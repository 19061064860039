import React from "react";
import SaveFormHint from "./saveFormHintComponent";

export default function SaveButtonComponent({isFormLocked, formUpdated, setFormUpdated, handleSubmit}) {
    return (
        <>
            <div className="form-group mx-2 d-flex justify-content-center align-middle my-1">
                  { !isFormLocked && !formUpdated &&
                    <button disabled={formUpdated?true:""} className="btn btn-sm btn-warning mx-2" onClick={() => setFormUpdated(true)}>Edit</button>
                  }
                { !isFormLocked &&
                    <button disabled={(isFormLocked || !formUpdated)?true:""} className="btn btn-sm btn-success" onClick={() => handleSubmit()}>Save</button>
                }
            </div>
            <div className="d-flex justify-content-center align-middle">
                  { !isFormLocked && 
                  <>
                  <SaveFormHint />
                  </>
                  }
                    
            </div>
        </>
    );
}