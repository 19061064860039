import React, {useState} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import Preferences from './components/Preferences/Preferences';
import Home from './components/Home/Home';
import Login from './components/Auth/Login';
import My404Component from './components/My404Component';
import Register from './components/Auth/Register';
import ResetPassword from './components/Auth/ResetPassword';
import Apply from './components/Apply/Apply';
import Application from './components/Application/Application';
import Review from './components/Review/Review';
import HallTicket from './components/HallTicket/HallTicket';

function App() {
  
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/register' element={<Register />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/preferences' element={<Preferences />}/>
          <Route path='/login' element={<Login />} />
          <Route path='/apply' element={<Apply />} />
          <Route path='/application' element={<Application />} />
          <Route path='/review' element={<Review />} />
          <Route path='/my-hallticket' element={<HallTicket />} />
          <Route path='*' exact={true} element={<My404Component />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;