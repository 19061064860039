import React, {useEffect, useState, useRef} from "react";
import ApplicationService from "../../services/applicationService";
import parse from "html-react-parser";
import SaveButtonComponent from "./SaveButtonComponent";
import RedStar from "../RedStar";
import upi_qr from "../../upi_qr.png";
import { unreservedFeesAmount, scStFeesAmount } from "../../constants";

export default function ({application, applicant, setApplication, token, isFormLocked, position}){

    const [paymentMode, setPaymentMode] = useState("UPI");
    const [paymentAmount, setPaymentAmount] = useState(unreservedFeesAmount);
    const [transactionId, setTransactionId] = useState("");
    const [paymentInfo, setPaymentInfo] = useState({});
    const [paymentProofFile, setPaymentProofFile] = useState(null);

    const [formError, setFormError] = useState("");
    const [formSuccess, setFormSuccess] = useState("")
    const [formUpdated, setFormUpdated] = useState(false);

    const paymentModes = ["UPI", "NEFT/IMPS"];

    const formRef = useRef();

    useEffect(() => {
        setPaymentMode(application?.payment?.paymentMode || "UPI");
        setTransactionId(application?.payment?.transactionId);
        if (application?.payment?.paymentAmount)
            setPaymentAmount(application?.payment?.paymentAmount || paymentAmount);
    }, []);

    useEffect(() => {
        if (formError !== ""){
            alert("ERROR: "+formError);
        }
    }, [formError])

    useEffect(() => {
        setFormUpdated(true);
    }, [paymentMode, transactionId])

    useEffect(() => {
        // Prefill data if available
        if (!application || !application.payment) return; //Done: update states of new states
        const payment = application.payment;
        setPaymentInfo(payment);
    }, [application]);

    useEffect(() => {
        if (["SC", "ST(P)", "ST(H)"].includes(applicant.category)){
            setPaymentAmount(scStFeesAmount);
        }
    }, [applicant]);

    const setNewUpload = (name, file) => {
        setPaymentProofFile({name, file});
    }

    const handleSubmit = () => {
        setFormError("");

        if (!paymentMode || !transactionId){
            setFormError("Please enter all the details");
            setTimeout(() => {
                setFormError("");
            }, 10000);
            setFormUpdated(true);
            return;
        }

        if (transactionId.length < 5){
            setFormError("Please enter a valid transactionId");
            setTimeout(() => {
                setFormError("");
            }, 10000);
            setFormUpdated(true);
            return;
        }


        // update form
        setFormUpdated(false);
        //DONE: Call new api to update application
        ApplicationService.updatePaymentInfo(application._id,{paymentMode, paymentAmount, transactionId, paymentProofFile}, token).then(
            (resolve) => {
                if (resolve.status === 200){
                    const p = resolve.data.application;
                    if (p){
                        //console.log("Got application", p);
                        setApplication(p);
                        setFormSuccess("Payment Info saved successfully!");
                        setTimeout(() => {
                            setFormSuccess("");
                        }, 5000);
                        setFormUpdated(false);
                    }else{
                        setFormError(resolve.data.message);
                    }
                }else{
                    console.log("error in updating paymentInfo Details", resolve);
                    setFormError(resolve.data.message);
                    setFormUpdated(true);
                }
            }, (reject) => {
                setFormError("Failed to update Payment Info Details");
                console.error("error in updating Payment Info Details", reject);
            }
        );
    };

    return (
        <div>
            <div className="card no-break">
                <div className="card-body">
                    <div className="card-title d-flex justify-content-between">
                        <h5>Payment Details</h5> {formUpdated && !isFormLocked && <p className="px-1 py-1 rounded bg-warning text-black"><strong>Unsaved!</strong></p>}
                    </div>

                    <div className="row">
                        <div className="col-3 position-relative" >
                            <img className="img img-fluid position-absolute end-0" style={{height: "inherit"}} src={upi_qr} alt={"UPI QR Code"}/>
                        </div>
                        <div className="form col" ref={formRef}>
                            <div className="form-group mb-5">
                                <label>
                                    Payment Mode <RedStar></RedStar>
                                </label>
                                <select className='form-control' value={paymentMode}
                                        onChange={e => setPaymentMode(e.target.value)}>
                                    {paymentModes.map((mode, index) => (
                                        <option key={`mode_${index}`} value={mode}>{mode}</option>))}
                                </select>
                            </div>
                            <div className="form-group mb-5">
                                <label>
                                    Payment Amount <RedStar></RedStar>
                                </label>
                                <input maxLength={50} disabled={true}
                                       className='form-control' style={{}}
                                       onChange={e => setPaymentAmount(e.target.value)} type="text"
                                       value={paymentAmount}/>
                            </div>

                            <div className="form-group mb-5">
                                <label>
                                    Transaction Id <RedStar></RedStar>
                                </label>
                                <input maxLength={50} minLength={6} disabled={(isFormLocked || !formUpdated) ? true : ""}
                                       className='form-control' style={{}}
                                       onChange={e => setTransactionId(e.target.value)} type="text"
                                       value={transactionId}/>
                            </div>

                            <div className="form-group">
                                <label>
                                    Payment Proof File (PDF) <RedStar></RedStar>
                                </label>

                                {
                                    application.paymentProofUrl &&
                                    <div className="form-text mb-1">
                                        <a className="form-control bg-success text-white" href={application.paymentProofUrl} >Uploaded File Link</a>
                                    </div>
                                }

                                <input type={'file'} onChange={e => {
                                    const [f] = e.target.files;
                                    setNewUpload('paymentProof', f);
                                }} disabled={(isFormLocked || !formUpdated) ? true : ""} className='form-control'/>
                            </div>
                        </div>
                        <div className="col-3 rounded border p-2">
                            <div className="form-label">
                                NEFT/IMPS Details:
                            </div>
                            <div className="form-text fw-bold lh-lg">
                                Name: Assam Industrial Development Corporation <br></br>
                                Account Number: 140901000007057 <br></br>
                                IFSC Code: IOBA0001409 <br></br>
                            </div>
                        </div>
                    </div>

                </div>
                {!isFormLocked &&
                    <div className="row text-center">
                        <br></br>
                        <small className="text-danger">{formError}</small>
                        <small className="text-success">{formSuccess}</small>
                        <br></br>
                    </div>
                }

                {!isFormLocked &&
                    <SaveButtonComponent isFormLocked={isFormLocked} formUpdated={formUpdated}
                                         setFormUpdated={setFormUpdated} handleSubmit={handleSubmit}/>
                }
            </div>

        </div>
    );
}