import React, {useState, useEffect} from "react";
import ApplicationService from "../../services/applicationService";
import parse from 'html-react-parser';
import RedStar from "../RedStar";
import SaveButtonComponent from "./SaveButtonComponent";

export default function EducationDetailFormReview({application, setApplication, token, isFormLocked, position, user}){

    //DONE: Replace states with new ones
    const [class10School, setClass10School] = useState("");
    const [class10Marks, setClass10Marks] = useState("");
    const [class10Stream, setClass10Stream] = useState("");
    const [class10YOP, setClass10YOP] = useState("");
    const [class10Board, setClass10Board] = useState("");
    // const [class12School, setClass12School] = useState("");
    // const [class12Marks, setClass12Marks] = useState("");
    // const [class12Stream, setClass12Stream] = useState("");
    // const [class12YOP, setClass12YOP] = useState("");
    // const [class12Board, setClass12Board] = useState("");
    const [graduationSchool, setGraduationSchool] = useState("");
    const [graduationMarks, setGraduationMarks] = useState("");
    const [graduationStream, setGraduationStream] = useState("");
    const [graduationYOP, setGraduationYOP] = useState("");
    const [graduationBoard, setGraduationBoard] = useState("");
    const [postGraduationSchool, setPostGraduationSchool] = useState("");
    const [postGraduationMarks, setPostGraduationMarks] = useState("");
    const [postGraduationStream, setPostGraduationStream] = useState("");
    const [postGraduationYOP, setPostGraduationYOP] = useState("");
    const [postGraduationBoard, setPostGraduationBoard] = useState("");

    const [formError, setFormError] = useState("");
    const [formSuccess, setFormSuccess] = useState("")
    const [formUpdated, setFormUpdated] = useState(false);

    useEffect(() => { 
      if (formError !== ""){
          alert("ERROR: "+formError);
      }
  }, [formError])

    useEffect(() => {
      setFormUpdated(true);
    }, [class10Marks, class10School, class10Stream, 
      // class12School, class12Marks, class12Stream, class12Stream,
      graduationSchool, graduationMarks, graduationStream,
      postGraduationSchool, postGraduationMarks, postGraduationStream, class10YOP, class10Board, // class12YOP, class12Board,
      graduationYOP, graduationBoard, postGraduationYOP, postGraduationBoard]) //DONE: update states list

    useEffect(() => {
      // Prefill data if available
      if (!application || !application.education) return; //Done: update states of new states
      const education = application.education;

      setClass10School((education.class10School)?education.class10School:"");
      setClass10Marks((education.class10Marks)? education.class10Marks:"");
      setClass10Stream((education.class10Stream)? education.class10Stream:"");
      setClass10YOP((education.class10YOP)? education.class10YOP:"");
      setClass10Board((education.class10Board)? education.class10Board:"");

      // setClass12School((education.class12School) ? education.class12School : "");
      // setClass12Marks((education.class12Marks) ? education.class12Marks : "");
      // setClass12Stream((education.class12Stream) ? education.class12Stream : "");
      // setClass12YOP((education.class12YOP)? education.class12YOP:"");
      // setClass12Board((education.class12Board)? education.class12Board:"");

      setGraduationSchool((education.graduationSchool) ? education.graduationSchool : "");
      setGraduationMarks((education.graduationMarks) ? education.graduationMarks : "");
      setGraduationStream((education.graduationStream) ? education.graduationStream : "");
      setGraduationYOP((education.graduationYOP)? education.graduationYOP:"");
      setGraduationBoard((education.graduationBoard)? education.graduationBoard:"");

      setPostGraduationSchool((education.postGraduationSchool) ? education.postGraduationSchool : "");
      setPostGraduationMarks((education.postGraduationMarks) ? education.postGraduationMarks : "");
      setPostGraduationStream((education.postGraduationStream) ? education.postGraduationStream : "");
      setPostGraduationYOP((education.postGraduationYOP)? education.postGraduationYOP:"");
      setPostGraduationBoard((education.postGraduationBoard)? education.postGraduationBoard:"");
      
    }, [application]);

    const handleSubmit = () => {
      //console.log("handle submit", class10School, class10Marks, class10Stream, class12School, class12Marks, class12Stream, graduationSchool, graduationStream, graduationMarks, postGraduationSchool, postGraduationMarks, postGraduationStream); //Done: New form validation

      setFormError("");

      if (position.minQualification === "Post-Graduate Degree" && 
        (!postGraduationBoard || !postGraduationMarks || !postGraduationSchool || !postGraduationStream || !postGraduationYOP)
      ){ setFormError("Post Graduation details can't be empty."); return;}

      // update form
      setFormUpdated(false);
      //DONE: Call new api to update application
      ApplicationService.updateEducation(application._id, class10School, class10Marks, class10Stream, "class12School", "class12Marks", "class12Stream", graduationSchool, graduationMarks, graduationStream, postGraduationSchool, postGraduationMarks, postGraduationStream, token,
        class10YOP, class10Board, "class12YOP", "class12Board", graduationYOP, graduationBoard, postGraduationYOP, postGraduationBoard).then((resolve) => {
          if (resolve.status === 200){
            const p = resolve.data.application;
              if (p){
                  //console.log("Got application", p);
                  setApplication(p);
                  setFormSuccess("Education Details saved successfully!");
                  setTimeout(() => {
                    setFormSuccess("");
                  }, 5000);
                  setFormUpdated(false);
              }else{
                  setFormError(resolve.data.message);
              }
          }else{
              //console.log("error in updating Education Details", resolve);
              setFormError(resolve.message);
              const data = resolve;
              let errors = data.message +"<br>"
              if (data.error){
                  for (let i = 0; i < data.error.length; i++) {
                      const element = data.error[i];
                      errors += element + "<br>"
                  }
                  setFormError(parse(errors));
              }
              setFormUpdated(true);
          }
      }, (reject) => {
          setFormError("Failed to update Education Details");
          console.error("error in updating Education Details", reject);
      })

    }

    if (!application)
      return <></>
    
    return (
      <>
            <div className="card no-break"> {/*style={{pageBreakAfter:"always"}}> */}
              <div className="card-body">
                <div className="card-title d-flex justify-content-between">
                      <h5>Educational Details</h5>
                </div>

                <div className=" text-center">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-light">
                          <th scope="row">Qualification</th>
                          <th scope="row">School/ College</th>
                          <th scope="row">Subject/ Stream/ Course</th>
                          <th scope="row">Year of Passing</th>
                          <th scope="row">Board/ Council/ University</th>
                          <th scope="row">Percentage of Marks/ CGPA</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <th scope="col"> Class 10 </th>
                          <td>{class10School}</td>
                          <td>{class10Stream}</td>
                          <td>{class10YOP}</td>
                          <td>{class10Board}</td>
                          <td>{class10Marks}</td>
                        </tr>
                        {/* <tr>
                          <th scope="col"> Class 12 </th>
                          <td>{class12School}</td>
                          <td>{class12Stream}</td>
                          <td>{class12YOP}</td>
                          <td>{class12Board}</td>
                          <td>{class12Marks}</td>
                        </tr> */}
                        <tr>
                          <th scope="col">3 Years Diploma in Civil Engineering / Technology</th>
                          <td>{graduationSchool}</td>
                          <td>{graduationStream}</td>
                          <td>{graduationYOP}</td>
                          <td>{graduationBoard}</td>
                          <td>{graduationMarks}</td>
                        </tr>
                        { (position.minQualification === "Post-Graduate Degree" || user.qualification === "Post-Graduate Degree") &&
                          <tr>
                            <th scope="col"> Post-Graduation</th>
                            <td>{postGraduationSchool}</td>
                            <td>{postGraduationStream}</td>
                            <td>{postGraduationYOP}</td>
                            <td>{postGraduationBoard}</td>
                            <td>{postGraduationMarks}</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                </div>
              </div>

            </div>
            <br></br>
            
      </>
    );
    
  }