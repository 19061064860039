import React from "react";

export default function PositionAtReviewComponent({position}){

    if (!position)
      return <></>

    
    return (
      <>
            <table className="table table-bordered">
                <thead className="">
                <tr>
                  <th scope="col">Position</th>
                  <th scope="col">Post-Code</th>
                </tr>
                </thead>
                <tbody>
                    
                  <tr key={position._id}>
                    <td key={position.positionName}>{position.positionName}</td>
                    <td key={position.postCode}>{position.postCode}</td>
                  </tr>
              
                </tbody>
                
            </table>
            
      </>
    );
    
  }