import React, {useState} from 'react';
import ApplicantService from '../../services/applicantService';
import brandlogo from '../../brandlogo.png';


export default function Login({setToken}) {
    
    const [registrationId, setRegistrationId] = useState();
    const [password, setPassword] = useState();
    const [loginErr, setLoginErr] = useState();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async e => {
        setLoading(true)
        e.preventDefault();
        const data = await ApplicantService.login(
          registrationId,
          password
        );
        //console.log("data", data);
        if (data.status === 200){
            localStorage.setItem("token", data.data.token);
            if (setToken)
            setToken(data.data.token);
            setLoginErr(null);
            //console.log()
        }else{
            localStorage.clear();
            setLoginErr(data.message);
            //console.log("error state updated", data.message);
            setTimeout(() => {
                setLoginErr("");
              }, 10000);
        }

        setLoading(false);
        
      }

    const cardStyle = {
        minWidth:'300px'
    };

  return(
    <div className='text-center'> 
        <header style={{backgroundColor:"beige", minHeight:"7vw", position:'relative', display: "flex", justifyContent: "space-evenly", alignItems: "center", overflowX: "auto"}}>
            <a className="" href="/" style={{}}><img style={{height:"5vw"}} src={brandlogo} alt="logo"/></a>
            
            <img className='' style={{height:"7vw", marginLeft: "10%"}} alt='AIDC' src='header_image_removebg.png'/>

            <nav className="navigation" style={{width:"20vw"}}>
                <a className="btn btn-sm mx-1" style={{borderColor: 'white', fontSize:"1vw"}} href="/">Home</a>
                <a className="btn btn-sm mx-1" style={{borderColor: 'white', fontSize:"1vw"}} href="/register">Register</a>
                <a className="btn btn-sm mx-1" style={{borderColor: 'white', fontSize:"1vw"}} href="/reset-password">Reset Password</a>
            </nav>
        </header>
    
        <br></br>
        <div className='row'>
        <div className="col"></div>
            <div className='col-4' style={cardStyle}>
                    <div className='card mx-auto position-relative'>
                    {/* <CircleLoader className='position-absolute top-50 end-40 mx-auto' color="#36d7b7" size={100} loading={true}/> */}
                    <p className='card-header'>Login</p>
                    <div className='card-body'>
                        <form className='form'>
                            <div className="form-group">
                                <label > 
                                    Registration Id 
                                </label>
                                <input className='form-control' onChange={e => setRegistrationId(e.target.value)} type="text" id='registrationId' aria-describedby='regHelp' name='registrationId'/>
                            </div>
                            <div className="form-group">
                                <label >
                                    Password
                                </label>
                                <input className='form-control' onChange={e => setPassword(e.target.value)} type="password" id="password" name='password'/>
                            </div>
                            <br></br>
                            <div>
                                <button disabled={loading?true:""} className='btn btn-success btn-sm' type="submit" onClick={handleSubmit}>{loading ? "Processing.." : "Submit"}</button>
                                <br/>
                                <small className='text-danger'>{loginErr}</small><br></br>
                            </div>
                        </form>
                    </div>
                    <div className='card-footer'>Registration Id and password are shared with you via email after successful registration<br></br>
                    <center><small className='text-danger'>For any queries please contact us at <br></br>admin@aidcrecruitment.co.in</small></center>
                    <br></br>
                        <div className='row mb-3'>
                            <div className='col'><a href="/register" className='btn btn-primary btn-sm'>Register</a></div>
                            <div className='col'><a href="/reset-password" className='btn btn-primary btn-sm'>Reset Password</a></div>
                        </div>
                    </div>
                        
                </div>
            </div>
            <div className='col'/>
        </div>
        
        <br></br>
        <div><a target='_blank' className="" href="https://aidc202411.s3.ap-south-1.amazonaws.com/advt.pdf" style={{fontSize:'1.5vw'}}>Download the detailed advertisement &#128279;</a></div>
        <p className='text-danger'>For each section of the application, do not forget to click on save button.</p>
    </div>
    
  )
}

// Login.propTypes = {
//     setToken: PropTypes.func.isRequired
// }