import React, {useState} from "react";
import { logout } from "../../utils";
import ApplyComponent from "../ApplyComponent";
import Login from "../Auth/Login";
import brandlogo from "../../brandlogo.png";


export default function Apply(){

    const [token, setToken] = useState("");
    
    const tok = localStorage.getItem("token");
    //console.log("token", tok);
    if(!token) {
        if (tok && tok !== ""){
            setToken(tok);
        }else{
            //console.log("redirect to login");
            return <Login setToken={setToken}/>
        }
    }else{
        //console.log("returned to Apply", token);
    }

    
    return (
        <div className="">
            <header style={{backgroundColor:"beige", minHeight:"7vw", position:'relative', display: "flex", justifyContent: "space-evenly", alignItems: "center", overflowX: "auto"}}>
                <a className="" href="/" style={{}}><img style={{height:"5vw"}} src={brandlogo} alt="logo"/></a>
                
                <img className='' style={{height:"7vw", marginLeft: "10%"}} alt='AIDC' src='header_image_removebg.png'/>

                <nav className="navigation" style={{width:"20vw"}}>
                    <a className="btn btn-sm mx-1" style={{borderColor: 'white', fontSize:"1vw"}} href="/">Home</a>
                    <button className="btn  btn-sm btn-danger" style={{fontSize:"1vw"}} onClick={logout}>Logout</button>
                </nav>
            </header>

<br></br><br></br>
            <ApplyComponent token={token}/>
        </div>
    );
    
}