import React, {useState, useEffect} from "react";
import ApplicationService from "../../services/applicationService";
import parse from 'html-react-parser';
import RedStar from "../RedStar";
import SaveButtonComponent from "./SaveButtonComponent";

export default function AddressDetailForm({application, setApplication, token, isFormLocked}){

    //DONE: Replace states with new ones
    const [presentAddress, setPresentAddress] = useState("");
    const [permanentAddress, setPermanentAddress] = useState("");
    const [presentPinCode, setPresentPinCode] = useState("");
    const [permanentPinCode, setPermanentPinCode] = useState("");
    const [presentDistrict, setPresentDistrict] = useState("");
    const [permanentDistrict, setPermanentDistrict] = useState("");
    const [presentState, setPresentState] = useState("");
    const [permanentState, setPermanentState] = useState("");
    const [sameAddress, setSameAddress] = useState(false);

    const [formError, setFormError] = useState("");
    const [formSuccess, setFormSuccess] = useState("")
    const [formUpdated, setFormUpdated] = useState(false);

    useEffect(() => { 
      if (formError !== ""){
          alert("ERROR: "+formError);
      }
  }, [formError])

    useEffect(() => {
      setFormUpdated(true);
    }, [presentAddress, permanentAddress, presentPinCode, permanentPinCode, presentDistrict, permanentDistrict, presentState, permanentState]) //DONE: update states list

    useEffect(() => {
      // Prefill data if available
      if (!application || !application.address) return; //Done: update states of new states
      const address = application.address;

      setPresentAddress((address.presentAddress)?address.presentAddress:"");
      setPresentPinCode((address.presentPinCode)?address.presentPinCode:"");
      setPresentDistrict((address.presentDistrict)?address.presentDistrict:"");
      setPresentState((address.presentState)?address.presentState:"");

      setPermanentAddress((address.permanentAddress)?address.permanentAddress:"");
      setPermanentPinCode((address.permanentPinCode)?address.permanentPinCode:"");
      setPermanentDistrict((address.permanentDistrict)?address.permanentDistrict:"");
      setPermanentState((address.permanentState)?address.permanentState:"");

      
    }, [application, sameAddress]);

    useEffect(() => {
      if (sameAddress){
        setPermanentAddress(presentAddress);
        setPermanentPinCode(presentPinCode);
        setPermanentDistrict(presentDistrict);
        setPermanentState(presentState);
      }
    }, [permanentAddress, permanentDistrict, permanentPinCode, permanentState, presentAddress, presentDistrict, presentPinCode, presentState, sameAddress])

    const handleSubmit = () => {
      //console.log("handle submit", presentAddress, permanentAddress, presentPinCode, permanentPinCode, presentDistrict, permanentDistrict, presentState, permanentState); //Done: New form validation

      setFormError("");

      // update form
      setFormUpdated(false);
      //DONE: Call new api to update application
      ApplicationService.updateAddress(application._id, presentAddress, permanentAddress, presentPinCode, permanentPinCode, presentDistrict, permanentDistrict, presentState, permanentState, token).then((resolve) => {
          if (resolve.status === 200){
            const p = resolve.data.application;
              if (p){
                  //console.log("Got application", p);
                  setApplication(p);
                  setFormSuccess("Address Details saved successfully!");
                  setTimeout(() => {
                    setFormSuccess("");
                  }, 5000);
                  setFormUpdated(false);
              }else{
                  setFormError(resolve.data.message);
              }
          }else{
              //console.log("error in updating Address Details", resolve);
              setFormError(resolve.message);
              const data = resolve;
              let errors = data.message +"<br>"
              if (data.error){
                  for (let i = 0; i < data.error.length; i++) {
                      const element = data.error[i];
                      errors += element + "<br>"
                  }
                  setFormError(parse(errors))
              }
              setFormUpdated(true);
          }
      }, (reject) => {
          setFormError("Failed to update Address Details");
          console.error("error in updating Address Details", reject);
      })

    }

    if (!application)
      return <></>
    
    return (
      <>
            <div className="card no-break">
              <div className="card-body">
                <div className="card-title d-flex justify-content-between">
                      <h5>Address Details</h5> {formUpdated && <p className="px-1 py-1 rounded bg-warning text-black"><strong>Unsaved!</strong></p>}
                </div>

                <div className="form ">
                  <div className="row  py-2"> 
                    <div className="form-group col">
                      <h6>Present Address Details</h6>
                      <div className="col">
                        <div className="form-group col">
                          <label> 
                              Address with P.O.& P.S. <RedStar></RedStar>
                          </label>
                          <textarea maxLength={250} disabled={(isFormLocked || !formUpdated)?true:""} className='form-control' rows="5" onChange={e => setPresentAddress(e.target.value)}  value={presentAddress}/>
                        </div>
                        <div className="form-group col">
                          <label> 
                              Pincode <RedStar></RedStar>
                          </label>
                          <input maxLength={6} disabled={(isFormLocked || !formUpdated)?true:""} className='form-control' onChange={e => setPresentPinCode(e.target.value)} type="number" value={presentPinCode}/>

                          <label> 
                              State <RedStar></RedStar>
                          </label>
                          <input maxLength={50} disabled={(isFormLocked || !formUpdated)?true:""} className='form-control' onChange={e => setPresentState(e.target.value)} type="text" value={presentState}/>

                          <label> 
                              District <RedStar></RedStar>
                          </label>
                          <input maxLength={50} disabled={(isFormLocked || !formUpdated)?true:""} className='form-control' onChange={e => setPresentDistrict(e.target.value)} type="text" value={presentDistrict}/>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col">
                      <h6>Permanent Address Details</h6>
                      <div className="col">
                       
                            <div className="form-group col">
                              <label> 
                                  Address with P.O.& P.S. <RedStar></RedStar>
                              </label>
                              <textarea maxLength={250} disabled={(isFormLocked || !formUpdated)?true:""} className='form-control' rows="5" onChange={e => setPermanentAddress(e.target.value)}  value={permanentAddress}/>
                            </div>
                            <div className="form-group col">
                              <label> 
                                  Pincode <RedStar></RedStar>
                              </label>
                              <input maxLength={6} disabled={(isFormLocked || !formUpdated)?true:""} className='form-control' onChange={e => setPermanentPinCode(e.target.value)} type="number" value={permanentPinCode}/>

                              <label> 
                                  State <RedStar></RedStar>
                              </label>
                              <input maxLength={50} disabled={(isFormLocked || !formUpdated)?true:""} className='form-control' onChange={e => setPermanentState(e.target.value)} type="text" value={permanentState}/>

                              <label> 
                                  District <RedStar></RedStar>
                              </label>
                              <input maxLength={50} disabled={(isFormLocked || !formUpdated)?true:""} className='form-control' onChange={e => setPermanentDistrict(e.target.value)} type="text" value={permanentDistrict}/>
                            </div>
                          </div>
                      
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <label > 
                      Same as present address &nbsp;
                  </label>
                  <input disabled={(isFormLocked || !formUpdated)?true:""} type="checkbox" onChange={e => {setSameAddress(e.target.checked)}} checked={sameAddress} />
                        
                  <div className="row text-center">
                    <small className="text-danger">{formError}</small>
                    <small className="text-success">{formSuccess}</small>
                  </div>
                  <SaveButtonComponent isFormLocked={isFormLocked} formUpdated={formUpdated} setFormUpdated={setFormUpdated} handleSubmit={handleSubmit}/>
                </div>
              </div>
      </>
    );
    
  }