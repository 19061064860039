import React, {useState} from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Login from "../Auth/Login";
import { logout } from "../../utils";
import UserProfileComponent from "../UserProfileComponent";
import brandlogo from "../../brandlogo.png";
import ApplicationsListComponent from "./ApplicationsListComponent";

export default function Home() {
    const [token, setToken] = useState("");
    
    const tok = localStorage.getItem("token");
    if(!token) {
        if (tok && tok !== ""){
            //console.log("token", tok);
            setToken(tok);
        }else{
            //console.log("redirect to login");
            return <Login setToken={setToken}/>
        }
    }else{
        //console.log("returned home");
    }

    return (
        <div className="">
        <header style={{backgroundColor:"beige", minHeight:"7vw", position:'relative', display: "flex", justifyContent: "space-evenly", alignItems: "center", overflowX: "auto"}}>
            <a className="" href="/" style={{}}><img style={{height:"5vw"}} src={brandlogo} alt="logo"/></a>
            
            <img className='' style={{height:"7vw", marginLeft: "10%"}} alt='AIDC' src='header_image_removebg.png'/>

            <nav className="navigation" style={{width:"20vw"}}>
                <a className="btn btn-sm mx-1" style={{borderColor: 'white', fontSize:"1vw"}} href="/">Home</a>
                <button className="btn  btn-sm btn-danger" style={{fontSize:"1vw"}} onClick={logout}>Logout</button>
            </nav>
        </header>

<br></br><br></br>
            <center><h6 className="mx-auto">User Profile:</h6></center>
            <UserProfileComponent token={token}/>
            <div className="container">
                <div className="row ">
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col-3"><a href="/apply" className="btn btn-primary btn-sm text-white ">Apply for Positions</a></div>
                </div>
            </div>
            <br></br>
            <ApplicationsListComponent token={token} />
        </div>
    );
}