import React from "react";

export default function ApplicationStatusBar({application}) {
    
    if (!application)
        return <></>

    return (
        <>
            <table className="table table-bordered no-break">
                <thead className="table-dark">
                <tr>
                  <th scope="col">Application ID</th>
                  <th scope="col">Status</th>
                  <th scope="col">Remark</th>
                </tr>
                </thead>
                <tbody>
                    
                  <tr key={application._id}>
                    <td key={application.applicationId}>{application.applicationId}</td>
                    
                    {application.status === "submitted" || application.status === "approved" ? 
                      <td key={application.status} className="text-success">{application.status}</td>
                      :
                      <td key={application.status}>{application.status}</td>
                    }

                    <td key={application.remark} className="text-dark">{application.remark}</td>
                    
                  </tr>
              
                </tbody>
                
            </table>
        </>
    )
}