import React from "react";

export default function PositionComponent({position}){

    if (!position)
      return <></>

    
    return (
      <>
            <table className="table table-bordered">
                <thead className="">
                <tr>
                  <th scope="col">Position</th>
                  <th scope="col">Post-Code</th>
                  <th scope="col">Age (years)</th>
                  <th scope="col">Level</th>
                  <th scope="col">Group</th>
                  <th scope="col">No. of Vacancies</th>
                  <th scope="col">Reservation category</th>
                  <th scope="col">Qualification</th>
                  <th scope="col">Experience</th>
                </tr>
                </thead>
                <tbody>
                    
                  <tr key={position._id}>
                    <td key={position.positionName}>{position.positionName}</td>
                    <td key={position.postCode}>{position.postCode}</td>
                    <td key={position.ageRange}>{position.ageRange}</td>
                    <td key={position.level}>{position.level}</td>
                    <td key={position.group}>{position.group}</td>
                    <td key={position.vacancy}>{position.vacancy}</td>
                    <td key={position.reservationCategories}>{Object.entries(position.reservationCategories).map( ([category,vacancy], i) => (<div key={category}>{category}({vacancy})<br></br></div>))}</td>
                    <td key={position.qualification}>{position.qualification === "Graduate" ? "3 Years Diploma in Civil Engineering / Technology" : position.qualification}</td>
                    <td key={position.experience}>{position.experience.map(entry => (<div>{entry.name}</div>))}</td>
                  </tr>
              
                </tbody>
                
            </table>
            
      </>
    );
    
  }