import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../utils";
import Login from "../Auth/Login";
import Home from "../Home/Home";
import brandlogo from "../../brandlogo.png";

import { useSearchParams } from 'react-router-dom';
import ApplicationComponent from "./ApplicationComponent";
import UserProfileComponent from "../UserProfileComponent";
import ApplicantService from "../../services/applicantService";


export default function Application(){
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    //console.log(searchParams);

    const positionId = searchParams.get('positionId');

    const [token, setToken] = useState("");
    const [user, setUser] = useState({});

    const text1 = "Note: \n1. Applicants without graduation in commerce are not eligible for Junior Accounts Assistant position.\n2. Applicant must submit graduation details in order to qualify for application submission.";
    const text2 = "Note: \n1. Applicant must submit 3 Years Diploma in Civil Engineering / Technology details in order to qualify for application submission."

    function showInitPopup(){
        if (positionId === "64ef8faac2963dd3af13ce5f")
            alert(text1);
        else
            alert(text2);
    }



    if (!positionId || positionId === ""){
        return <Home />
    }else{
        
    }
    
    //console.log("positionId", positionId);

    const tok = localStorage.getItem("token");
    //console.log("token", tok);
    if(!token) {
        if (tok && tok !== ""){
            setToken(tok);
            if (!user.firstName){
                ApplicantService.getProfile(tok).then((resolve) => {
                    //console.log("resolve response", resolve);
                    if (resolve.status === 200){
                        setUser(resolve.data.user);
                        showInitPopup();
                    }else{
                        // navigate("/");
                    }
                }, (reject) => {
                  //console.log("rejected response", reject);
                });
            }
        }else{
            //console.log("redirect to login");
            return <Login setToken={setToken}/>
        }
    }else{
        //console.log("returned to Application", token);
    }
    
    return (
        <div className="">
            <header style={{backgroundColor:"beige", minHeight:"7vw", position:'relative', display: "flex", justifyContent: "space-evenly", alignItems: "center", overflowX: "auto"}}>
                <a className="" href="/" style={{}}><img style={{height:"5vw"}} src={brandlogo} alt="logo"/></a>
                
                <img className='' style={{height:"7vw", marginLeft: "10%"}} alt='AIDC' src='header_image_removebg.png'/>

                <nav className="navigation" style={{width:"20vw"}}>
                    <a className="btn btn-sm mx-1" style={{borderColor: 'white', fontSize:"1vw"}} href="/">Home</a>
                    <button className="btn  btn-sm btn-danger" style={{fontSize:"1vw"}} onClick={logout}>Logout</button>
                </nav>
                </header>
            <br></br><br></br>
            <UserProfileComponent token={token} />
            <ApplicationComponent positionId={positionId} token={token} user={user} />
        </div>
    );
    
}