import React, {useState} from "react";

import ApplicantService from "../services/applicantService";
import { logout } from "../utils";

export default function UserProfileComponent({token}) {

    const [user, setUser] = useState({});

    if (!user.firstName){
      ApplicantService.getProfile(token).then((resolve) => {
          //console.log("resolve response", resolve);
          if (resolve.status === 200)
              setUser(resolve.data.user);
          else{
            logout();
          }
      }, (reject) => {
        //console.log("rejected response", reject);
      });
    }
      

    return (
        <div className="container no-break">
           
              <div className="row"><div className="col pt-2 pb-2"><strong>Registration Id:</strong> {user.registrationId}</div></div>
              <div className="row border-top border-start border-end">
                  <div className="col-3 pt-2 pb-2"><strong>First Name:</strong><br></br> {user.firstName}</div>
                  <div className="col-5 pt-2 pb-2"><strong>Middle Name:</strong><br></br>  {user.middleName}</div>
                  <div className="col-4 pt-2 pb-2"><strong>Last Name:</strong><br></br>  {user.lastName}</div>
              </div>
              <div className="row border-top border-start border-end ">
                  <div className="col-3 pt-2 pb-2"><strong>Date Of Birth:</strong><br></br>  {user.dob}</div>
                  <div className="col-5 pt-2 pb-2"><strong>Qualification:</strong><br></br>  {user.qualification === "Graduate" ? "3 Years Diploma in Civil Engineering / Technology" : user.qualification}</div>
                  <div className="col-4 pt-2 pb-2"><strong>Category:</strong><br></br>  {user.category === "OBC" ? "OBC/MOBC" : user.category}</div>
              </div>
              <div className="row border-top border-start border-end ">
                  <div className="col-3 pt-2 pb-2"><strong>Mobile:</strong><br></br>  {user.mobile}</div>
                  <div className="col-5 pt-2 pb-2"><strong>Email:</strong><br></br>  {user.email}</div>
                  <div className="col-4 pt-2 pb-2"><strong>PWD status:</strong><br></br> {user.isPWD ? "Yes" : "NIL"}</div>
              </div>
              <div className="row border-top border-start border-end border-bottom">
                  <div className="col-3 pt-2 pb-2"><strong>Gender:</strong><br></br>  {user.gender}</div>
                  <div className="col-5 pt-2 pb-2"><strong>Age (as on 01-11-2024):</strong><br></br>  {user.ageYears} years {user.ageMonths} months and {user.ageDays} days</div>
                  <div className="col-4 pt-2 pb-2"><strong>Employment Exchange Number: </strong>{user.employmentExchangeNumber}<br></br> <strong>District: </strong>{user.employmentExchangeDistrict}</div>
              </div>
              { user.isPWD && 
                  <div className="row border-top border-start border-end border-bottom">
                    <div className="col-3 pt-2 pb-2"><strong>Percentage of Disability:</strong><br></br>  {user.PWDMeasurable}</div>
                    <div className="col-3 pt-2 pb-2"><strong>UDID:</strong><br></br>  {user.UDID}</div>
                    <div className="col-3 pt-2 pb-2"><strong>PWD Category:</strong><br></br>  {user.PWDCategory}</div>
                  </div>
              }
              <div className="row border-top border-start border-end border-bottom">
                <div className="col-3 pt-2 pb-2"><strong>Ex-Servicemen Status:</strong> {user.isExMilitary? "Yes": "No"} </div>
                <div className="col-3 pt-2 pb-2"><strong>EWS Status:</strong> {user.isEWS? "Yes": "No"} </div>
              </div>
              <br></br>
            
            


        </div>
    );
}