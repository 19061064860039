import React, {useState, useEffect} from "react";
import ApplicationService from "../../services/applicationService";
import parse from 'html-react-parser';
import uuid from 'react-uuid';
import RedStar from "../RedStar";
import ApplicantService from "../../services/applicantService";
import SaveButtonComponent from "./SaveButtonComponent";

export default function ExperienceDetailForm({application, setApplication, token, isFormLocked, position}){

    const [experience, setExperience] = useState([]);
    const [graduationDate, setGraduationDate] = useState(null);
    const [organisation, setOrganisation] = useState("");
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [designation, setDesignation] = useState("");
    const [exp, setExp] = useState(0);
    const [curExp, setCurExp] = useState([]);

    const [formError, setFormError] = useState("");
    const [formSuccess, setFormSuccess] = useState("")
    const [formUpdated, setFormUpdated] = useState(false);
    const [totalExperince, setTotalExperience] = useState({years:0,months:0,days:0, yearsTotal:0});
    const [lastExperienceDate, setLastExperienceDate] = useState();

    function differenceInMonths(date1, date2){
      if (!date1 || !date2)
        return 0;
      const diffTime = new Date(date2) - new Date(date1);
      if (diffTime < 0){
        return diffTime;
      }else{
        setFormError("");
      }
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      const diffMonths = Math.abs(diffDays/30).toPrecision(2);
      return diffMonths;
    }

    useEffect(() => { 
      if (formError !== ""){
          alert("ERROR: "+formError);
      }
  }, [formError])

    useEffect(() => {
      setFormUpdated(true);
      setCurExp([organisation, designation, fromDate, toDate, exp]);
    }, [organisation, designation, fromDate, toDate, exp]) //DONE: update states list

    useEffect(() => {
      // Prefill data if available
      if (!application || !application.experience) return; //Done: update states of new states
      const experiences = application.experience;
      //console.log("experience from application type", typeof experiences)
      setExperience(experiences);
      
    }, [application]);

    useEffect(() => {
      //console.log("lastExpDate", lastExperienceDate);
    }, [lastExperienceDate]);

    useEffect(() => {
      let newTotalExp = {years:0, months:0, days:0, yearsTotal:0};
      for (let i=0; i<experience.length; i++){
        const item = experience[i];
        if (item.length > 2){
          setFromDate(item[3]);
          setLastExperienceDate(item[3]);
          const exp = item[4];
          if (exp){
            newTotalExp.years+=exp.years;
            newTotalExp.months+=exp.months;
            newTotalExp.days+=exp.days;
          }
        }
      }
      if (newTotalExp.days >= 30){
        newTotalExp.months+= parseInt(newTotalExp.days/30);
        newTotalExp.days = newTotalExp.days%30;
      }
      if (newTotalExp.months >= 12){
        newTotalExp.years += parseInt(newTotalExp.months/12);
        newTotalExp.months = newTotalExp.months%12;
      }
      const totalYears = newTotalExp.years + (newTotalExp.months/12) + (newTotalExp.days/365);
      newTotalExp.yearsTotal += totalYears;
      setTotalExperience(newTotalExp);
    }, [experience]);

    useEffect(() => {console.log(totalExperince);}, [totalExperince]);

    const handleRemoveExp = (key) => {
      const expToRemove = experience.find(item => item[0]+"-"+item[2].toString() === key)
      if (experience.length === 1)
        setLastExperienceDate();
      else
        setLastExperienceDate(new Date(expToRemove[2]));
      const newExperience = experience.filter(item => item[0]+"-"+item[2].toString() !== key)
      setExperience(newExperience);
    }

    const handleAddExp = () => {
      if (!curExp || curExp.length !== 5 || curExp[0] === "" || curExp[1] === "" || curExp[2] === "" || curExp[3] === "" || curExp[4] === ""
        || !curExp[0] || !curExp[1] || !curExp[2] || !curExp[3] || !curExp[4]
      ){
        setFormError("Enter all fields. Cannot add invalid experience data to form");
        return;
      }

      if (fromDate === toDate){
        setFormError("fromDate cannot be same as toDate. Cannot add invalid experience data to form");
        return;
      }
      
      if (curExp[4] < 0){
        setFormError("FromDate should be before of toDate");
        setTimeout(() => {
          setFormError("");
        }, 10000); 
        return;
      }

      //console.log("experience", experience)

      if ( new Date(fromDate).getTime() < new Date(lastExperienceDate).getTime()){
        setFormError("New experience period cannot overlap with previous experience");
        return;
      }

      if (!graduationDate){
        if (position)
          setFormError(`Set ${position.minQualification === "Graduate" ? "3 Years Diploma in Civil Engineering / Technology" : position.minQualification} Completion Date first!`);
        return;
      }
    
      if ( new Date(fromDate).getTime() < new Date(graduationDate).getTime()){
        let gr = "3 Years Diploma in Civil Engineering / Technology";
        if (position.minQualification === "Post-Graduate Degree")
          gr = "post graduation"
        setFormError("New experience period cannot be before "+gr+" date");
        return;
      }

      if ( new Date(toDate).getTime() > new Date().getTime()){
        setFormError("New experience period cannot extend to a future date");
        return;
      }

      const newList = experience.concat([curExp])
      setExperience(newList);
      //console.log("new experience", experience)
      setOrganisation("");
      setDesignation("");
      setExp(1);
      setFormError("");
    }

    const handleSubmit = () => {
      //console.log("handle submit", experience); //Done: New form validation

      setFormError("");

      if (experience.length === 0){
        setFormError("Form is empty");
        setTimeout(() => {
          setFormError("");
        }, 10000);
        setFormUpdated(true);
        return;
      }

      if (position && position.experience[0].duration && !isNaN(position.experience[0].duration)){
          if (totalExperince.yearsTotal < position.experience[0].duration){
            setFormError("Kindly fill all the experience details. Total experience should be atleast "+position.experience[0].duration+" years.");
            setTimeout(() => {
              setFormError("");
            }, 10000);
            setFormUpdated(true);
            return;
          }else{
            //console.log("applicant has enough experience", totalExperince.years, position.experience[0].duration);
          }
      }else{
        //console.log("position details not valid", position);
      }

      // update form
      setFormUpdated(false);
      //DONE: Call new api to update application
      ApplicationService.updateExperience(application._id, experience, token).then((resolve) => {
          if (resolve.status === 200){
            const p = resolve.data.application;
              if (p){
                  //console.log("Got application", p);
                  setApplication(p);
                  setFormSuccess("Experience Details saved successfully!");
                  setTimeout(() => {
                    setFormSuccess("");
                  }, 5000);
                  setFormUpdated(false);
              }else{
                  setFormError(resolve.data.message);
              }
          }else{
              //console.log("error in updating Experience Details", resolve);
              setFormError(resolve.message);
              const data = resolve;
              let errors = data.message +"<br>"
              if (data.error){
                  for (let i = 0; i < data.error.length; i++) {
                      const element = data.error[i];
                      errors += element + "<br>"
                  }
                  setFormError(parse(errors))
              }
              setFormUpdated(true);
          }
      }, (reject) => {
          setFormError("Failed to update Experience Details");
          console.error("error in updating Experience Details", reject);
      })

    }

    if (!application)
      return <></>
    
    return (
      <>
            <div className="card no-break">
              <div className="card-body">
                <div className="card-title d-flex justify-content-between">
                      <h5>Post Qualification Experience Details</h5> {formUpdated && !isFormLocked && <p className="px-1 py-1 rounded bg-warning text-black"><strong>Unsaved!</strong></p>}
                </div>
                
                {
                  position && !isFormLocked &&
                  <div className="">
                    <p>Please enter the work experience you gained after completing minimum qualification for this position</p>
                  {position.minQualification === "Post-Graduate Degree" && <label>Enter Your Post Graduation Date</label> } 
                  {position.minQualification === "Graduate" && <label>Enter Your 3 Years Diploma in Civil Engineering / Technology Completion Date</label>}
                  <RedStar></RedStar>
                  <input disabled={(isFormLocked || !formUpdated)?true:""} className="form-control w-25" onChange={e => {setGraduationDate(e.target.value); }} type="date" value={graduationDate}/>
                </div>
                }
                
                
                <div className="form text-center">
                  
                    <table className="table table-bordered w-100">
                      <thead>
                        <tr className="table-light">
                          <th scope="row">Organisation <RedStar></RedStar></th>
                          <th scope="row">Designation <RedStar></RedStar></th>
                          <th scope="row">From Date <RedStar></RedStar></th>
                          <th scope="row">To Date <RedStar></RedStar></th>
                          <th scope="row">Experience In Months<RedStar></RedStar></th>
                          { !isFormLocked &&
                            <th scope="row">Action</th>
                          }
                        </tr>
                      </thead>

                      <tbody>
                        {  experience.map( (experience1, index) => (
                          <tr key={uuid()+String(Date.now())}>
                            <td key={uuid()}>{experience1[0]}</td>
                            <td key={uuid()}>{experience1[1]}</td>
                            <td key={uuid()}>{experience1[2]}</td>
                            <td key={uuid()}>{experience1[3]}</td>
                            <td key={uuid()}>{(experience1[4].years*12)+experience1[4].months}</td>
                            { !isFormLocked &&
                              <td key={uuid()+"-del"}><button disabled={(isFormLocked || !formUpdated)?true:""} className="btn btn-sm btn-danger" onClick={() => handleRemoveExp(experience1[0]+"-"+experience1[2].toString())}>remove</button></td>
                            }
                          </tr>
                        ))}
                        { !isFormLocked &&
                          <tr>
                            <td><input maxLength={50} disabled={(isFormLocked || !formUpdated)?true:""} className='form-control' onChange={e => setOrganisation(e.target.value)} type="text" value={organisation}/></td>
                            <td><input maxLength={50} disabled={(isFormLocked || !formUpdated)?true:""} className='form-control' onChange={e => setDesignation(e.target.value)} type="text" value={designation}/></td>
                            <td><input disabled={(isFormLocked || !formUpdated)?true:""} className='form-control' onChange={e => {setFromDate(e.target.value); setExp(ApplicantService.preciseDateDiff(new Date(e.target.value), new Date(toDate)));}} type="date" value={fromDate}/></td>
                            <td><input disabled={(isFormLocked || !formUpdated)?true:""} className='form-control' onChange={e => {setToDate(e.target.value); setExp(ApplicantService.preciseDateDiff(new Date(fromDate),new Date(e.target.value)));}} type="date" value={toDate}/></td>
                            <td><input disabled={true} className='form-control' type="number" min={1} value={(exp.years*12 + exp.months) || 0}/></td>
                            <td><button disabled={(isFormLocked || !formUpdated)?true:""} className="btn btn-sm btn-success" onClick={() => handleAddExp()}>Add</button></td>
                          </tr>
                        }
                        
                      </tbody>
                    </table>
                    {(totalExperince.days + totalExperince.months + totalExperince.years) > 0 &&
                      <div>Total Experience: {totalExperince.years} years, {totalExperince.months} months and {totalExperince.days} days</div>
                    }
                    
                </div>
                
                { !isFormLocked &&
                  <div className="row text-center">
                    <br></br>
                    <small className="text-danger">{formError}</small>
                    <small className="text-success">{formSuccess}</small>
                    <br></br>
                  </div>
                }
                
                { !isFormLocked &&
                  <SaveButtonComponent isFormLocked={isFormLocked} formUpdated={formUpdated} setFormUpdated={setFormUpdated} handleSubmit={handleSubmit}/>
                }  
              </div>

            </div>
            <br></br>
            
      </>
    );
    
  }