import React, {useEffect, useState} from "react";
import ApplicationService from "../../services/applicationService";
import PositionService from "../../services/PositionService";
import ApplicationStatusBar from "../Application/ApplicationStatusBar";
import PositionAtReviewComponent from "../PositionAtReviewComponent";
import EducationDetailFormReview from "../Application/EducationDetailFormReview";
import AddressDetailFormReview from "../Application/AddressDetailFormReview";
import ExperienceDetailForm from "../Application/ExperienceComponent";
import UserProfileComponent from "../UserProfileComponent";
import DocumentUploadComponent from "../Application/DocumentUploadComponent";
import PersonalDetailFormReview from "../Application/PersonalDetailFormReview";
import PaymentInfoComponent from "../Application/PaymentInfoComponent";

export default function ApplicationReviewComponent({token, applicationId, user}) {
    

    const [application, setApplication] = useState();
    const [position, setPosition] = useState();
    const [tocAccepted, setTocAccepted] = useState(false);
    const [renderInvalid, setRenderInvalid] = useState(false);
    const [renderInvalidMsg, setRenderInvalidMsg] = useState("");

    const submitForm = async (token, applicationId) => {
        if (!applicationId){
            alert("Invalid applicationId");
        }

        const response = await ApplicationService.submitForm(token, applicationId);
        
        if (response.status === 200){
            setApplication(response.data.application);
            alert("Application Submission Successful! \nSubject to verification");
        }else
            alert(response.message);

    }

    const getDocumentLink = (name) =>{
        if (!application || !application.documents){
            return null;
        }
        //console.log("getting document link", name);
        const index = application.documents.findIndex(e => e.name === name)

        if (index === -1){
            return null;
        }else{
            return application.documents[index].url;
        }
    }

    useEffect( () => {
        async function fetchData() {
            // You can await here
            const response = await ApplicationService.getUserApplications(token);
            if (response && response.status === 200){
                const applications = response.data.applications;
                const index = applications.findIndex(e => e.applicationId === applicationId)
                if (index === -1){
                    // alert("Invalid application id provided");
                    setRenderInvalid(true);
                    setRenderInvalidMsg("Invalid application id provided");
                }else{
                    setApplication(applications[index]);
                    const response1 = await PositionService.getPositions(token);
                    const positions = response1.data.positions;
                    const p = positions.find(x => x._id === applications[index].position);
                    setPosition(p);
                }
            }else{
                // alert("Failed to retrive application data. Try refreshing the page");
                setRenderInvalid(true);
                setRenderInvalidMsg("Failed to retrive application data. Try refreshing the page");
            }
          }
          fetchData();
    }, [applicationId, token])

    if (renderInvalid){
        return (
            <>
            <div className="text-center">
                <h6>Application Review</h6>
            </div>
            <div className="container text-center">
                <h6 className="py-2 text-danger">{renderInvalidMsg}</h6>
                <a className="btn btn-success" href="/">Go to HomePage</a>
            </div>
            </>
        )
    }

    if (!application || !position){
        return (
            <>
            <div className="text-center">
                <h6>Application Review</h6>
            </div>
           
            </>
        )
    }

    return (
        <>
            <div className="container position-relative">
                <div className="text-center">
                { (application.status === "submitted" || application.status === "approved" || application.status === "rejected") && 
                    <h6>ONLINE APPLICATION FORM</h6>
                }
                { application.status !== "submitted" && application.status !== "approved" && application.status !== "rejected" &&   
                    <h6>Application Review</h6>
                }
                
                <br></br>
                </div>
                { (application.status === "submitted" || application.status === "approved" || application.status === "rejected") && 
                <>
                    <div className="btn btn-sm btn-primary position-absolute end-0 top-0 mx-1" onClick={() => {window.print();}}>Print/Download</div>
                    <div className="position-absolute start-0 top-0 mx-1"><a className="btn btn-sm btn-primary text-white" href="/">Back to HomePage</a></div>
                    </>
                }
                <div className="row py-1">
                    <div className="col-10">
                        <UserProfileComponent token={token} />
                    </div>

                    <div className="col-2 mt-4">
                        <img className="img img-fluid border my-2" src={getDocumentLink('photograph')} alt="photograph" />
                        <img className="img img-fluid border my-2" src={getDocumentLink('signature')} alt="signature" />
                    </div>
                </div>

                <PositionAtReviewComponent position={position} />
                <ApplicationStatusBar application={application}/>
                <PersonalDetailFormReview application={application} setApplication={setApplication} token={token} isFormLocked={true}/>
                <AddressDetailFormReview application={application} setApplication={setApplication} token={token} isFormLocked={true}/>
                <EducationDetailFormReview application={application} setApplication={setApplication} token={token} position={position} isFormLocked={true} user={user}/>
                <ExperienceDetailForm application={application} setApplication={setApplication} token={token} isFormLocked={true}/>
                <PaymentInfoComponent application={application} setApplication={setApplication} token={token} isFormLocked={true} position={position} applicant={user} />
                <DocumentUploadComponent application={application} setApplication={setApplication} token={token} isFormLocked={true} position={position} user={user}/>
                

                { application.status !== "submitted" && application.status !== "approved" && application.status !== "rejected" &&

                    <>
                        <div className="form-control">
                            <div className="row">
                                <div className="col-1 px-3 py-2"><input type="checkbox" onClick={()=> {setTocAccepted(!tocAccepted); if(!tocAccepted){alert("Please check all the details carefully. The form cannot be edited after final submission.");}}}></input></div>
                                <div className="col text-secondary">
                                I hereby declare that all the information entered by me in the application form is
true to the best of my knowledge and belief and nothing has been concealed or suppressed. I also understand that
in case, any of my statements is found to be untrue during any stage of recruitment and thereafter, I shall be disqualified for this
position and I shall be liable for any penal action.
                                </div>
                            </div>
                        </div>

                    <br></br>

                        <div className="card">
                            <div className="card-body">
                                <center>
                                <button className="btn btn-success mx-5" disabled={tocAccepted ? false : true}  onClick={() => submitForm(token, application._id)}>Submit Application</button>
                                </center>
                            </div>
                        </div>
                    </>
                    
                }

                
                { (application.status === "submitted" || application.status === "approved" || application.status === "rejected") && 
                    <>
                    <center><div className="btn btn-sm btn-primary mx-1" onClick={() => {window.print();}}>Print/Download</div></center>
                    </>
                    
                }
            </div>
            <br></br>
        </>
    )
}