export const API_URL = "https://api.aidcrecruitment.co.in";
// export const API_URL = "http://localhost:5000";
// export const API_URL = "http://3.7.74.123:5000";

export const unreservedFeesAmount = "Rs 400";
export const scStFeesAmount = "Rs 250";

export const EmploymentExchangeDistricts = [
    "Kamrup (M)",
    "Jorhat",
    "Dibrugarh",
    "Sonitpur",
    "Karbi Anglong",
    "Cachar",
    "Barpeta",
    "Bongaigaon",
    "Dhemaji",
    "Dhubri",
    "Goalpara",
    "Golaghat",
    "Dima Hasao",
    "Hailakkandi",
    "Karimganj",
    "Kokrajhar",
    "North Lakhimpur",
    "Mangaldoi",
    "Morigaon",
    "Nagaon",
    "Nalbari",
    "Sivasagar",
    "Tinsukia",
    "Hojai",
    "Udalguri"
    ];
