import React, {useState, useRef, useEffect} from "react";

export default function PersonalDetailFormReview({application, setApplication, token, isFormLocked}){

    const [fatherName, setFatherName] = useState("");
    const [motherName, setMotherName] = useState("");
    const [maritalStatus, setMaritalStatus] = useState("");
    const [spouseName, setSpouseName] = useState("");
    const [formError, setFormError] = useState("");
    const [formSuccess, setFormSuccess] = useState("")
    const [formUpdated, setFormUpdated] = useState(false);

    const formRef = useRef()

    useEffect(() => { 
      if (formError !== ""){
          alert("ERROR: "+formError);
      }
  }, [formError])

    useEffect(() => {
      setFormUpdated(true);
      setFormError("");
      setFormSuccess("");
      //console.log(formSuccess, formUpdated)
    }, [formSuccess, formUpdated])

    useEffect(() => {
      // Prefill data if available
      if (!application || !application.personal) return;

      setFatherName(application.personal.fatherName);
      setMotherName(application.personal.motherName);
      setMaritalStatus(application.personal.maritalStatus);
      setSpouseName(application.personal.spouseName);
    }, [application]);

  
    if (!application)
      return <></>
    
    return (
      <>
            <div className="card no-break">
              <div className="card-body">
                  <div className="card-title d-flex justify-content-between">
                        <h5>Personal Details </h5> 
                  </div>
                <div className="form" ref={formRef}>
                  
                  <div className="row">
                    <div className="form-group col">
                        <label> 
                            Father's Name 
                        </label>
                        <p>{fatherName}</p>
                    </div>
                    <div className="form-group col">
                        <label> 
                            Mother's Name 
                        </label>
                        <p>{motherName}</p>
                    </div>
                    <div className="form-group col">
                        <label> 
                            Marital Status 
                        </label>
                        <p>{maritalStatus}</p>
                    </div>
                    { maritalStatus === "married" &&
                      <div className="form-group col">
                        <label> 
                            Spouse Name 
                        </label>
                        <p>{spouseName}</p>
                        </div>
                    }
                  </div>
                </div>
              </div>

            </div>
            <br></br>
      </>
    );
    
  }