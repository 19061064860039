import React, {useState, useEffect} from "react";
import ApplicationService from "../../services/applicationService";
import parse from 'html-react-parser';
import RedStar from "../RedStar";
import SaveButtonComponent from "./SaveButtonComponent";

export default function DocumentUploadComponent({application, setApplication, token, isFormLocked, position, user}) {

    const [documents, setDocuments] = useState([]);
    const [formError, setFormError] = useState("");
    const [formSuccess, setFormSuccess] = useState("")
    const [formUpdated, setFormUpdated] = useState(true);

    const [newUploads, setNewUploads] = useState([]);

    let totalDocuments = 0;

    function incrementTotalDocuments(){totalDocuments++};

    // useEffect(() => {
    //   setFormUpdated(true);
    // }, [documents]) //DONE: update states list


    useEffect(() => {
      // Prefill data if available
      if (!application || !application.documents) return; //Done: update states of new states
      const documents = application.documents;
      //console.log("Document from application type", typeof documents)
      setDocuments(documents);
      
    }, [application]);

    const setNewUpload = (name, file) => {
        const index = newUploads.findIndex(e => e.name === name);

        if (index === -1){
            let update = newUploads.concat([{name, file}]);
            setNewUploads(update);
        }else{
            //console.log(newUploads[index]);
            newUploads[index].file = file;
            setNewUploads(newUploads);
        }
        //console.log("new uploads", newUploads);
    }

    const getDocumentLink = (name) =>{
        //console.log("getting document link", name);
        const index = documents.findIndex(e => e.name === name)

        if (index === -1){
            return null;
        }else{
            return documents[index].url;
        }
    }

    const handleSubmit = () => {
      //console.log("handle submit", documents); //Done: New form validation

      setFormError("");

      // update form
      setFormUpdated(false);

        //console.log("total Documents:", totalDocuments);

      //DONE: Call new api to update application
      ApplicationService.updateDocuments(application._id, newUploads, token, totalDocuments).then((resolve) => {
          if (resolve.status === 200){
            const p = resolve.data.application;
              if (p){
                  //console.log("Got application", p);
                  setApplication(p);
                  alert(resolve.message);
                  setFormSuccess("documents Details saved successfully!");
                  setTimeout(() => {
                    setFormSuccess("");
                  }, 5000);
                //   setFormUpdated(true);
              }else{
                  setFormError(resolve.data.message);
                //   setFormUpdated(true);
              }
          }else{
              //console.log("error in updating documents Details", resolve);
              alert(resolve.message);
              setFormError(resolve.message);
              const data = resolve;
              let errors = data.message +"<br>"
              if (data.error){
                  for (let i = 0; i < data.error.length; i++) {
                      const element = data.error[i];
                      errors += element + "<br>"
                  }
                  setFormError(parse(errors))
              }
              setFormUpdated(true);
          }
      }, (reject) => {
          alert(reject.message);
          setFormError("Failed to update Experience Details");
          console.error("error in updating Experience Details", reject);
          setFormUpdated(true);
      })

    }

    if (!application)
      return <></>
    
    return (
      <>
            <div className="card">
              <div className="card-body">
                <div className="card-title d-flex justify-content-between">
                      <h5>Upload Documents</h5>{formUpdated && !isFormLocked &&  <p className="px-1 py-1 rounded bg-warning text-black"><strong>Unsaved!</strong></p>}
                </div>
                { !isFormLocked &&
                    <small className="text-warning px-3"><RedStar></RedStar><RedStar></RedStar>Note: Uploaded document should not be more than 500KB</small>
                }

                <div className="form text-center">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-light">
                          <th scope="row">Document Name</th> 
                          <th scope="row">Preview/Link</th>
                          { !isFormLocked &&
                            <th scope="row">Update</th>
                          }
                          
                        </tr>
                      </thead>

                      <tbody>
                        <tr className="">
                            <th>Photograph <RedStar></RedStar><br></br>Photo JPG: 1200 pixels x 1200 pixels. Click <inline className="text-warning" onClick={() => {
                                // eslint-disable-next-line no-multi-str
alert("1. Please upload your recent passport size photograph. \n2. The photograph should not be more \
than 6 months old. \n3. The photo-print should be clear and with a good quality. It \
should have a white/off white background, full face, neck and shoulders, front view, eyes \
open. \n4. Maximum \
file size: 500KB \n5. File Formats Supported: Only JPEG, JPG \n6. Please upload the same \
photograph, which you will affix on the Admit Card on the day of the test\
. \n8. Ensure that the photograph is scanned properly using a scanner only. Please do \
not use mobile phone or other mobile applicaons to scan your photograph");
                            }}>here</inline> for complete specifications.<br></br>File Size Limit: 500KB, Format: JPG/JPEG</th>
                            <td>
                                {incrementTotalDocuments()}
                                { getDocumentLink('photograph') && !isFormLocked &&
                                    <img className="img img-fluid" style={{width:"200px"}} src={getDocumentLink('photograph')+"?"+(new Date()).getTime()} alt="photograph" />
                                }
                                { getDocumentLink('photograph') && isFormLocked &&
                                    <a href={getDocumentLink('photograph')} target="_blank" rel="noreferrer">{getDocumentLink('photograph') ? "Attached" : "Not Attached"}</a>
                                }
                                { !getDocumentLink('photograph') && 
                                    <div>Not Attached</div>
                                }
                                {/* <a href={getDocumentLink('photograph')} target="_blank" rel="noreferrer">{getDocumentLink() ? "Attached" : "Not Attached"}</a>*/}
                            </td>
                            { !isFormLocked &&
                                <td style={{width:"33%"}}><input disabled={(isFormLocked || !formUpdated)?true:""} className='form-control'  onChange={e => {
                                        const [f] = e.target.files;
                                        setNewUpload('photograph', f);
                                    }} type="file"/></td>
                            }
                        </tr>
                        <tr className="">
                            <th>Signature <RedStar></RedStar><br></br>Signature size: Keep a proper size ( Dimensions 3.5 cm x 1.5cm (preferred))<br></br>File Size Limit: 500KB, Format: JPG/JPEG</th>
                            <td>
                                {incrementTotalDocuments()}
                                { getDocumentLink('signature') && !isFormLocked &&
                                    <img className="img img-fluid" style={{width:"200px"}} src={getDocumentLink('signature')+"?"+(new Date()).getTime()} alt="signature" />
                                }
                                { getDocumentLink('signature') && isFormLocked &&
                                    <a href={getDocumentLink('signature')} target="_blank" rel="noreferrer">{getDocumentLink('signature') ? "Attached" : "Not Attached"}</a>
                                }
                                { !getDocumentLink('signature') && 
                                    <div>Not Attached</div>
                                }
                                {/* <a href={getDocumentLink('signature')} target="_blank" rel="noreferrer">{getDocumentLink() ? "Attached" : "Not Attached"}</a> */}
                            </td>
                            { !isFormLocked &&
                                <td style={{width:"33%"}}><input disabled={(isFormLocked || !formUpdated)?true:""} className='form-control'  onChange={e => {
                                        const [f] = e.target.files;
                                        setNewUpload('signature', f);
                                    }} type="file" /></td>
                            }
                        </tr>
                        <tr className="">
                            <th>Identity Proof and Age Certificate (Aadhar/PAN Card/HSLC Admit card) <RedStar></RedStar><br></br>File Size Limit: 500KB, Format: PDF</th>
                            <td>
                                {incrementTotalDocuments()}
                                <a href={getDocumentLink('identity')} target="_blank" rel="noreferrer">{getDocumentLink('identity') ? "Attached" : "Not Attached"}</a></td>
                            { !isFormLocked &&
                                <td style={{width:"33%"}}><input disabled={(isFormLocked || !formUpdated)?true:""} className='form-control'  onChange={e => {
                                    const [f] = e.target.files;
                                    setNewUpload('identity', f);
                                }} type="file" /></td>
                            }
                        </tr>
                        <tr className="">
                            <th>Class 10 Marksheet <RedStar></RedStar><br></br>File Size Limit: 500KB, Format: PDF</th>
                            <td>
                                {incrementTotalDocuments()}
                                <a href={getDocumentLink('class10marksheet')} target="_blank" rel="noreferrer">{getDocumentLink('class10marksheet') ? "Attached" : "Not Attached"}</a></td>
                            { !isFormLocked &&
                                <td style={{width:"33%"}}><input disabled={(isFormLocked || !formUpdated)?true:""} className='form-control'  onChange={e => {
                                    const [f] = e.target.files;
                                    setNewUpload('class10marksheet', f);
                                }} type="file" /></td>
                            }
                        </tr>
                        {/* <tr className="">
                            <th>Class 12 Marksheet <RedStar></RedStar><br></br>File Size Limit: 500KB, Format: PDF</th>
                            <td>
                                {incrementTotalDocuments()}
                                <a href={getDocumentLink('class12marksheet')} target="_blank" rel="noreferrer">{getDocumentLink('class12marksheet') ? "Attached" : "Not Attached"}</a></td>
                            { !isFormLocked &&
                                <td style={{width:"33%"}}><input disabled={(isFormLocked || !formUpdated)?true:""} className='form-control'  onChange={e => {
                                    const [f] = e.target.files;
                                    setNewUpload('class12marksheet', f);
                                }} type="file" /></td>
                            }
                        </tr> */}
                        <tr className="">
                            <th>3 Years Diploma in Civil Engineering / Technology Marksheet <RedStar></RedStar><br></br>File Size Limit: 500KB, Format: PDF</th>
                            <td>
                                {incrementTotalDocuments()}
                                <a href={getDocumentLink('graduation')} target="_blank" rel="noreferrer">{getDocumentLink('graduation') ? "Attached" : "Not Attached"}</a></td>
                            { !isFormLocked &&
                                <td style={{width:"33%"}}><input disabled={(isFormLocked || !formUpdated)?true:""} className='form-control'  onChange={e => {
                                    const [f] = e.target.files;
                                    setNewUpload('graduation', f);
                                }} type="file" /></td>
                            }
                        </tr>
                        { (position.minQualification === "Post-Graduate Degree" || user.qualification === "Post-Graduate Degree") &&
                            <tr className="">
                                <th>Post Graduation Marksheet {position.minQualification === "Post-Graduate Degree" && <RedStar></RedStar>}<br></br>File Size Limit: 500KB, Format: PDF</th>
                                <td>
                                    {position.minQualification === "Post-Graduate Degree" && incrementTotalDocuments()}
                                    <a href={getDocumentLink('postgraduation')} target="_blank" rel="noreferrer">{getDocumentLink('postgraduation') ? "Attached" : "Not Attached"}</a></td>
                                { !isFormLocked &&
                                <td style={{width:"33%"}}><input disabled={(isFormLocked || !formUpdated)?true:""} className='form-control'  onChange={e => {
                                        const [f] = e.target.files;
                                        setNewUpload('postgraduation', f);
                                    }} type="file" /></td>
                                }
                            </tr>
                        }

                        {/* {
                            application.computerExperience &&
                            application.computerExperience.map(
                                (exp, index) => (
                                    <tr className="">
                                        <th>Computer Diploma / Certificate {index+1}: {exp[0]}<RedStar></RedStar><br></br>File Size Limit: 500KB, Format: PDF</th>
                                        <td>
                                        {incrementTotalDocuments()}
                                        <a href={getDocumentLink(`computerExperience-${index+1}`)} target="_blank" rel="noreferrer">{getDocumentLink(`computerExperience-${index+1}`) ? "Attached" : "Not Attached"}</a>
                                        </td>
                                        { !isFormLocked &&
                                            <td style={{width:"33%"}}><input disabled={(isFormLocked || !formUpdated)?true:""} className='form-control'  onChange={e => {
                                                const [f] = e.target.files;
                                                setNewUpload(`computerExperience-${index+1}`, f);
                                            }} type="file" /></td>
                                        }
                                    </tr>
                                )
                            )
                        } */}

                        {
                            application.experience &&
                            application.experience.map(
                                (exp, index) => (
                                    <tr className="">
                                        <th>Experience Certificate {index+1}: {exp[0]}<RedStar></RedStar><br></br>File Size Limit: 500KB, Format: PDF</th>
                                        <td>
                                        {incrementTotalDocuments()}
                                        <a href={getDocumentLink(`experience-${index+1}`)} target="_blank" rel="noreferrer">{getDocumentLink(`experience-${index+1}`) ? "Attached" : "Not Attached"}</a>
                                        </td>
                                        { !isFormLocked &&
                                            <td style={{width:"33%"}}><input disabled={(isFormLocked || !formUpdated)?true:""} className='form-control'  onChange={e => {
                                                const [f] = e.target.files;
                                                setNewUpload(`experience-${index+1}`, f);
                                            }} type="file" /></td>
                                        }
                                    </tr>
                                )
                            )
                        }

                        {
                            user && (user.category === "ST(P)" || user.category === "ST(H)" || user.category === "SC" || user.category === "OBC") &&
                            <tr className="">
                                <th>Caste Certificate <RedStar></RedStar><br></br>File Size Limit: 500KB, Format: PDF</th>
                                <td>
                                    {incrementTotalDocuments()}
                                    <a href={getDocumentLink('caste_certificate')} target="_blank" rel="noreferrer">{getDocumentLink('caste_certificate') ? "Attached" : "Not Attached"}</a></td>
                                { !isFormLocked &&
                                    <td style={{width:"33%"}}><input disabled={(isFormLocked || !formUpdated)?true:""} className='form-control'  onChange={e => {
                                        const [f] = e.target.files;
                                        setNewUpload('caste_certificate', f);
                                    }} type="file" /></td>
                                }
                            </tr>
                        }

                        {
                            user && (user.isEWS) &&
                            <tr className="">
                                <th>EWS Certificate <RedStar></RedStar><br></br>File Size Limit: 500KB, Format: PDF</th>
                                <td>
                                    {incrementTotalDocuments()}
                                    <a href={getDocumentLink('ews_certificate')} target="_blank" rel="noreferrer">{getDocumentLink('ews_certificate') ? "Attached" : "Not Attached"}</a></td>
                                { !isFormLocked &&
                                    <td style={{width:"33%"}}><input disabled={(isFormLocked || !formUpdated)?true:""} className='form-control'  onChange={e => {
                                        const [f] = e.target.files;
                                        setNewUpload('ews_certificate', f);
                                    }} type="file" /></td>
                                }
                            </tr>
                        }

                        {
                            user && user.isPWD &&
                            <tr className="">
                                <th>PWD Certificate <RedStar></RedStar><br></br>File Size Limit: 500KB, Format: PDF</th>
                                <td>
                                    {incrementTotalDocuments()}
                                    <a href={getDocumentLink('pwd_certificate')} target="_blank" rel="noreferrer">{getDocumentLink('pwd_certificate') ? "Attached" : "Not Attached"}</a></td>
                                { !isFormLocked &&
                                    <td style={{width:"33%"}}><input disabled={(isFormLocked || !formUpdated)?true:""} className='form-control'  onChange={e => {
                                        const [f] = e.target.files;
                                        setNewUpload('pwd_certificate', f);
                                    }} type="file" /></td>
                                }
                            </tr>
                        }

                        {
                            user && user.isExMilitary && 
                            <tr className="">
                                <th>Discharge certificate for ex service men <RedStar></RedStar><br></br>
                                File Size Limit: 500KB, Format: PDF
                                </th>
                                <td>
                                    {incrementTotalDocuments()}
                                    <a href={getDocumentLink('ex_military_proof')} target="_blank" rel="noreferrer">{getDocumentLink('ex_military_proof') ? "Attached" : "Not Attached"}</a></td>
                                { !isFormLocked &&
                                    <td style={{width:"33%"}}><input disabled={(isFormLocked || !formUpdated)?true:""} className='form-control'  onChange={e => {
                                        const [f] = e.target.files;
                                        setNewUpload('ex_military_proof', f);
                                    }} type="file" /></td>
                                }
                            </tr>
                        }

                        <tr className="">
                            <th>Employment Exchange Registration Certificate <RedStar></RedStar><br></br>
                            File Size Limit: 500KB, Format: PDF
                            </th>
                            <td>
                                {incrementTotalDocuments()}
                                <a href={getDocumentLink('employment_exchange_certificate')} target="_blank" rel="noreferrer">{getDocumentLink('employment_exchange_certificate') ? "Attached" : "Not Attached"}</a></td>
                            { !isFormLocked &&
                                <td style={{width:"33%"}}><input disabled={(isFormLocked || !formUpdated)?true:""} className='form-control'  onChange={e => {
                                    const [f] = e.target.files;
                                    setNewUpload('employment_exchange_certificate', f);
                                }} type="file" /></td>
                            }
                        </tr>
                      </tbody>
                    </table>
                </div>
                <br></br>
                <div className="row text-center">
                  <small className="text-danger">{formError}</small>
                  <small className="text-success">{formSuccess}</small>
                </div>
                <br></br>
                <SaveButtonComponent isFormLocked={isFormLocked} formUpdated={formUpdated} setFormUpdated={setFormUpdated} handleSubmit={handleSubmit}/>
              </div>

            </div>
            <br></br>
            
      </>
    );
    
}