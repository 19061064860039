import React, {useState, useEffect} from 'react';
import brandlogo from "../../brandlogo.png";
import ApplicantService from '../../services/applicantService';
import Login from '../Auth/Login';
import ApplicationService from '../../services/applicationService';
import { useSearchParams } from 'react-router-dom';

export default function HallTicket() {

    const [token, setToken] = useState("");
    const [user, setUser] = useState({});
    const [hallticket, setHallTicket] = useState({});
    const [searchParams] = useSearchParams();
    // console.log(searchParams);

    const id = searchParams.get('id');
    if (!id){
        alert("No hallticket id passed in query. Hall Ticket not found");
    }

    useEffect( () => {
        async function fetchData() {
            // You can await here

            const response = await ApplicationService.getHallTickets(token);
            if (response && response.status === 200){
                const hallTickets = response.data.hallTickets;
                const hallTicket = hallTickets.find(item => item._id === id);
                if (!hallTicket){
                    alert(`Invalid hallticket id. Hall Ticket not found`);
                }
                setHallTicket(hallTicket);
            }else{
                // alert("Failed to retrive application data. Try refreshing the page");
            }
        }

        fetchData();
    }, [id, token])

    const tok = localStorage.getItem("token");
    //console.log("token", tok);
    if(!token) {
        if (tok && tok !== ""){
            setToken(tok);
            if (!user.firstName){
                ApplicantService.getProfile(tok).then((resolve) => {
                    //console.log("resolve response", resolve);
                    if (resolve.status === 200){
                        setUser(resolve.data.user);
                    }else{
                        // navigate("/");
                    }
                }, (reject) => {
                  //console.log("rejected response", reject);
                });
            }
        }else{
            //console.log("redirect to login");
            return <Login setToken={setToken}/>
        }
    }else{
        //console.log("returned to Application", token);
    }

    

  return(
    <div style={{display:'flex', justifyItems:'center', flexDirection:'column', alignItems:'center'}}>
        <div className='' style={{borderStyle:"dashed", borderColor:"brown", width:'700px', display: 'flex', flexDirection:"column", fontSize:"10px"}}>
            <div className='flex-row' style={{justifyContent:'center', alignItems:"center", paddingTop:"10px"}}>
                <div className='' style={{color:"#292b75", fontWeight:'650', fontSize:'24px'}}>
                    <div>Assam Industrial Development Corporation</div>
                </div>
            </div>
            <br></br>
            <div className='flex-row' style={{justifyContent:'center', alignItems:'center', position:'relative'}}>
                <div style={{backgroundColor:'white' ,width:'200px', textAlign:'center', borderColor:'black', position:'absolute', fontWeight:'bold', zIndex:'10', fontSize:'12px', borderStyle:'solid', borderRadius:'5px', borderWidth:'1px', padding:'2px'}}>ADMIT CARD</div>
                <hr style={{position:'absolute', height:'1.5px', width:'696px', zIndex:'-1', left:'0'}}></hr>
            </div>
            <br></br>
            <div className='flex-row' style={{marginLeft:"10px", marginRight:"10px", justifyContent:'space-between'}}>   
                <div className='p-1' style={{fontSize:"13px", borderStyle:'solid', borderRadius:'5px', borderWidth:'1px', width:'220px'}}><strong>Roll No: {hallticket.rollNo}</strong></div>
                <div className='p-1' style={{fontSize:"13px", borderStyle:'solid', borderRadius:'5px', borderWidth:'1px', width:'220px'}}><strong>Registration Id: {hallticket.registrationId}</strong></div>
            </div>
            
            <div className='flex-column' style={{ marginTop:'5px', marginBottom:'5px'}}>
                <hr style={{ height:'1px', width:'696px', margin:'0'}}></hr>
                <div style={{margin:'5px', fontWeight:"bold", fontSize:"13px"}}>
                    Name of Post: {hallticket.postNames}
                </div>
                <hr style={{ height:'1px', width:'696px', margin:'0'}}></hr>
            </div>
            <div className='flex-row' style={{marginLeft:'5px', marginRight:'5px'}}>
                <div className='flex-column ' style={{width:'580px', fontSize:'10px'}}>
                    <div className='flex-row' >
                        <div className='flex-column'>
                            <div className='flex-row' style={{}}>
                                <div style={{borderStyle:'solid', paddingLeft:'3px', fontWeight:'bold', borderWidth:'0.5px', width:'150px'}}>Name of Candidate</div>
                                <div style={{borderStyle:'solid', paddingLeft:'3px', borderWidth:'0.5px', width:'430px'}}>{hallticket.candidateName}</div>                        
                            </div>
                            <div className='flex-row' style={{}}>
                                <div style={{borderStyle:'solid', paddingLeft:'3px', fontWeight:'bold', borderWidth:'0.5px', width:'150px'}}>Father's Name</div>
                                <div style={{borderStyle:'solid', paddingLeft:'3px', borderWidth:'0.5px', width:'430px'}}>{hallticket.fatherName}</div>
                            </div>
                            <div className='flex-row'>
                                <div style={{borderStyle:'solid', paddingLeft:'3px', fontWeight:'bold', borderWidth:'0.5px', width:'150px'}}>Address</div>
                                <div style={{borderStyle:'solid', paddingLeft:'3px', borderWidth:'0.5px', width:'430px'}}>{hallticket.address}</div>
                            </div>
                        </div>

                    </div>
                    <div className='flex-row' style={{borderStyle:'solid', borderWidth:'0.5px', justifyContent:'space-around', fontFamily:'serif', fontWeight:'bold', fontSize:'12px'}}>
                        <div>Date of Examination: {hallticket.examDate}</div>
                        <div className='flex-column'>
                            <div>
                                Reporting Time: {hallticket.reportingTime}
                            </div>
                            <div>
                                Duration of Test: {hallticket.examDuration}
                            </div>   
                        </div>
                    </div>
                    
                </div>

                <div className='flex-column' style={{width:'103px', justifyContent:'center', alignItems:'stretch'}}>
                    <img className='' style={{maxHeight:'100px'}}  src={hallticket.photograph} alt='photograph'/>
                </div>
            </div>

            <div className='flex-row'>
                <div className='flex-column' style={{margin:'5px', fontSize:'11px', width:'580px'}}>
                    <div className='flex-row'>
                        <div className='' style={{width:'350px', borderStyle:'solid', padding:'1px', borderWidth:'0.5px'}}>Venue of Examination</div>
                        <div className='' style={{width:'120px', borderStyle:'solid', padding:'1px', borderWidth:'0.5px', marginLeft:'3px'}}>Applicant's Signature</div>
                        <div className='' style={{width:'120px', borderStyle:'solid', padding:'1px', borderWidth:'0.5px', marginLeft:'3px'}}>Invigilator's Signature</div>
                    </div>
                    <div className='flex-row' style={{marginTop:"3px", height: "50px"}}>
                        <div className='' style={{width:'350px', borderStyle:'solid', padding:'1px', borderWidth:'0.5px'}}>{hallticket.examVenue}</div>
                        <div className='flex-column' style={{width:'120px', borderStyle:'solid', padding:'1px', borderWidth:'0.5px', marginLeft:'3px', justifyContent:'flex-end'}}><small style={{ fontSize:"8px"}}>(In Presence of Invigilator)</small></div>
                        <div className='' style={{width:'120px', borderStyle:'solid', padding:'1px', borderWidth:'0.5px', marginLeft:'3px'}}></div>
                    </div>
                </div>
                <div className='flex-column' style={{width:'100px', borderStyle:'solid', padding:'1px', borderWidth:'0.5px', marginTop:'5px', marginBottom:'5px', marginLeft:'5px', marginRight:'5px', alignItems:'center', justifyContent:'center'}}>
                    <img style={{width:'100px', maxHeight:'30px'}} className='img img-fluid' src={hallticket.signature} alt='signature'/>
                </div>
            </div>
        
            <p style={{marginLeft:"10px", marginRight:"10px", fontSize:'10px'}}>Dear Candidate,<br></br>
            Based on your application, you are invited to appear in Written Test for the above-mentioned position. This Admit Card is being issued to invite you to participate in selection process only. 
            This is not a confirmation of your final selection. 
            All claims in your application will be verified during the selection process. 
            If any claim is found to be untrue or it is found that you do not meet any of the eligibility conditions, your candidature for the post applied for will be cancelled without any notice. 
            The selection process would consist of a Written Test and Personal Interview/ Computer Proficiency Test.
            The Written Test would consist of questions to test your domain knowledge of the position you have applied for, General Awareness, Quantitative Aptitude, Reasoning and Analytical Skills. 
            Based on the scores obtained in the Written Test, candidates would be shortlisted for Personal Interview/ Computer Proficiency Test, which would be conducted in batches.
            The schedule for Personal Interview/ Computer Proficiency Test will be available on official website in due course of time. No Separate call letter will be issued for Personal Interview/ Computer Proficiency Test. You are advised to read the instructions given below carefully and bring all necessary documents, etc. as mentioned. The decision of the Selection Board shall be final.</p>
            <img style={{height:'30px', width: '80px', alignSelf:'flex-end', marginRight:'65px'}} className='img img-fluid' src='/spm_signature.png' alt='spm-signature' />
            <div className='row text-end' style={{marginLeft:"10px", marginRight:"10px"}}>
                <small>
                    <strong>For Recruitment Team&emsp;&emsp;&emsp;&emsp;</strong>
                </small>
            </div>
            <small><strong>N.B. PLEASE KEEP THIS ADMIT CARD IN SAFE CUSTODY.</strong> You will be asked to furnish this card as proof of attending the selection process, in case you are selected.</small>
            {/* <div style={{borderStyle:'dashed', borderWidth:'0.5px', height:'1px'}}></div> */}
            
            {/* <div className='flex-row' style={{justifyContent:'center', alignItems:"center", paddingTop:"10px"}}>
                
                <div className='' style={{color:"#292b75", fontWeight:'650', fontSize:'24px'}}>
                    <div>Assam Industrial Development Corporation</div>
                </div>
            </div>
            <br></br>
            <div className='flex-row' style={{justifyContent:'center', alignItems:'center', position:'relative'}}>
                <div style={{backgroundColor:'white' ,width:'200px', textAlign:'center', borderColor:'black', position:'absolute', fontWeight:'bold', zIndex:'10', fontSize:'12px', borderStyle:'solid', borderRadius:'5px', borderWidth:'1px', padding:'2px'}}>ADMIT CARD</div>
                <hr style={{position:'absolute', height:'1.5px', width:'696px', zIndex:'-1', left:'0'}}></hr>
            </div>
            <br></br>
            <div className='flex-row' style={{marginLeft:"10px", marginRight:"10px", justifyContent:'space-between'}}>   
                <div className='p-1' style={{borderStyle:'solid', borderRadius:'5px', borderWidth:'1px', width:'220px'}}><strong>Roll No: {hallticket.rollNo}</strong></div>
                <div className='p-1' style={{borderStyle:'solid', borderRadius:'5px', borderWidth:'1px', width:'220px'}}><strong>Registration Id: {hallticket.registrationId}</strong></div>
            </div>
            
            <div className='flex-column' style={{ marginTop:'5px', marginBottom:'5px'}}>
                <hr style={{ height:'1px', width:'696px', margin:'0'}}></hr>
                <div style={{margin:'5px', fontWeight:"bold", fontSize:"13px"}}>
                    Name of Post: {hallticket.postNames}
                </div>
                <hr style={{ height:'1px', width:'696px', margin:'0'}}></hr>
            </div>
            <div className='flex-row' style={{marginLeft:'5px', marginRight:'5px'}}>
                <div className='flex-column ' style={{width:'580px', fontSize:'10px'}}>
                    <div className='flex-row' >
                        <div className='flex-column'>
                            <div className='flex-row' style={{}}>
                                <div style={{borderStyle:'solid', paddingLeft:'3px', fontWeight:'bold', borderWidth:'0.5px', width:'150px'}}>Name of Candidate</div>
                                <div style={{borderStyle:'solid', paddingLeft:'3px', borderWidth:'0.5px', width:'430px'}}>{hallticket.candidateName}</div>                        
                            </div>
                            <div className='flex-row' style={{}}>
                                <div style={{borderStyle:'solid', paddingLeft:'3px', fontWeight:'bold', borderWidth:'0.5px', width:'150px'}}>Father's Name</div>
                                <div style={{borderStyle:'solid', paddingLeft:'3px', borderWidth:'0.5px', width:'430px'}}>{hallticket.fatherName}</div>
                            </div>
                            <div className='flex-row'>
                                <div style={{borderStyle:'solid', paddingLeft:'3px', fontWeight:'bold', borderWidth:'0.5px', width:'150px'}}>Address</div>
                                <div style={{borderStyle:'solid', paddingLeft:'3px', borderWidth:'0.5px', width:'430px'}}>{hallticket.address}</div>
                            </div>
                        </div>

                    </div>
                    <div className='flex-row' style={{borderStyle:'solid', borderWidth:'0.5px', justifyContent:'space-around', fontFamily:'serif', fontWeight:'bold', fontSize:'12px'}}>
                        <div>Date of Examination: {hallticket.examDate}</div>
                        <div className='flex-column'>
                            <div>
                                Reporting Time: {hallticket.reportingTime}
                            </div>
                            <div>
                                Duration of Test: {hallticket.examDuration}
                            </div>   
                        </div>
                    </div>
                    
                </div>

                <div className='flex-column' style={{width:'103px', justifyContent:'center', alignItems:'stretch'}}>
                    <img className='' style={{height:'100px'}}  src={hallticket.photograph} alt='photograph'/>
                </div>
            </div>

            <div className='flex-row'>
                <div className='flex-column' style={{margin:'5px', fontSize:'11px', width:'580px'}}>
                    <div className='flex-row'>
                        <div className='' style={{width:'350px', borderStyle:'solid', padding:'1px', borderWidth:'0.5px'}}>Venue of Examination</div>
                        <div className='' style={{width:'120px', borderStyle:'solid', padding:'1px', borderWidth:'0.5px', marginLeft:'3px'}}>Applicant's Signature</div>
                        <div className='' style={{width:'120px', borderStyle:'solid', padding:'1px', borderWidth:'0.5px', marginLeft:'3px'}}>Invigilator's Signature</div>
                    </div>
                    <div className='flex-row' style={{marginTop:"3px"}}>
                        <div className='' style={{width:'350px', borderStyle:'solid', padding:'1px', borderWidth:'0.5px'}}>{hallticket.examVenue}</div>
                        <div className='flex-column' style={{width:'120px', borderStyle:'solid', padding:'1px', borderWidth:'0.5px', marginLeft:'3px', justifyContent:'flex-end'}}><small style={{ fontSize:"8px"}}>(In Presence of Invigilator)</small></div>
                        <div className='' style={{width:'120px', borderStyle:'solid', padding:'1px', borderWidth:'0.5px', marginLeft:'3px'}}></div>
                    </div>
                </div>
                <div className='flex-column' style={{width:'100px', borderStyle:'solid', padding:'1px', borderWidth:'0.5px', marginTop:'5px', marginBottom:'5px', marginLeft:'5px', marginRight:'5px', alignItems:'center', justifyContent:'center'}}>
                    <img style={{width:'100px', height:'30px'}} className='img img-fluid' src={hallticket.signature} alt='signature'/>
                </div>
            </div> */}
        
        </div>
        <div className='flex-column'>
            <div className="btn btn-sm btn-primary m-1 hide-while-printing" style={{fontSize: "9px", alignSelf:'center'}} onClick={() => {window.print();}}>Print/Download</div>
            <div className='' style={{borderStyle:"dashed", borderColor:"brown", width:'700px', display: 'flex', flexDirection:"column", fontSize:"9px"}}>
                <div style={{borderStyle:'solid', borderWidth:'0.5px', fontSize:'11px', borderRadius:'5px', width:'120px', margin:'5px', textAlign:'center', alignSelf:'center', fontWeight:'bold'}}>INSTRUCTIONS</div>
                <ol style={{width:'650px'}}>
                    <li>This Admit Card has been issued to you to participate in the selection process only. It is not a confirmation of your final selection for the vacancy.</li>
                    <li>This Admit Card should be retained by the candidate in safe custody till the completion of the selection process.</li>
                    <li>Candidates shall bring this Admit Card to the examination hall.</li>
                    <li>Candidates shall carry at least one valid and original photo identity proof (Aadhar Card, Pan Card, Voter ID, etc.) during the examination.</li>
                    <li>All information submitted by you in your application shall be verified during the selection process. If conditions like educational qualification, age, experience, etc are not found as claimed by you in your application / or does not meet the criteria as advertised, your candidature would be annulled at any time during the selection process.</li>
                    <li>Please acquaint yourself about the venue before the date of the selection test.</li>

                    <li>Please plan to reach the venue at least 30 minutes before the Reporting Time.</li>

                    <li>PLEASE NOTE THAT CANDIDATES ARRIVING LATER THAN THE REPORTING TIME (as mentioned in the Admit Card) WILL NOT BE ALLOWED TO APPEAR FOR THE SELECTION PROCESS. No claims/ excuses about delay in journey, traffic etc will be entertained.</li>

                    <li>Candidates will not be allowed to go out of the venue during the selection test. Only after the test is completed, papers collected and tallied by invigilators, the candidates would be allowed to leave the examination hall.</li>

                    <li>If called for Personal Interview/ Computer Proficiency Test, candidates should bring this Admit Card along with the original and photocopy (self attested) of the following documents for verification - (a) Date of Birth proof, (b) Educational Qualifications, (c) Caste Certificate (for those claiming reservation), (d) Work Experience Certificates, (e) One passport size photograph.</li>

                    <li>Candidates should bring blue/black ball point pens, pencils and erasers for the selection test.</li>

                    <li>Please note that CELL PHONES, CALCULATORS, NOTEBOOKS, SMART WATCH or any other electronic gadget will not be allowed inside the examination hall.</li>

                    <li>In case any candidate is found using unfair means, or found misbehaving, her/his candidature will be annulled immediately.</li>

                    <li>All costs incurred to attend the selection process would be borne by the Candidate only.</li>
                    
                </ol>

                <p style={{fontWeight:'bold', alignSelf:'center', marginBottom:'10px'}}>******************</p>
            </div>
        </div>
    </div>
  );
}