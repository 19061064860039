import { API_URL } from "../constants";

export default class ApplicationService{

    static async submitForm(token, applicationId){

        let response = await fetch(API_URL + "/api/submit-application", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                applicationId
            })
        });

        response = await response.json();
        // console.log(response);
        
        return response;
    }

    static async getUserApplications(token){
        let response = await fetch(API_URL + "/api/get-user-applications", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        response = await response.json();
        // console.log(response);
        
        return response;
    }

    static async updatePaymentInfo(applicationId, paymentInfo, token){
        const fd = new FormData();
        fd.append('applicationId', applicationId);

        for (const key of Object.keys(paymentInfo)){
            if (key === 'paymentProofFile' && paymentInfo.paymentProofFile){
                fd.append(paymentInfo.paymentProofFile.name, paymentInfo.paymentProofFile.file);
            }
            fd.append(key, paymentInfo[key]);
        }

        let response = await fetch(API_URL + "/api/update-payment", {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: fd
        });

        response = await response.json();
        // console.log(response);

        return response;
    }

    static async updateAddress(applicationId, presentAddress, permanentAddress, presentPinCode, permanentPinCode, presentDistrict, permanentDistrict, presentState, permanentState, token){
        let response = await fetch(API_URL + "/api/update-address", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                "applicationId": applicationId,
                "presentAddress": presentAddress,
                "permanentAddress": permanentAddress,
                "presentPinCode": presentPinCode,
                "permanentPinCode": permanentPinCode,
                "presentDistrict": presentDistrict,
                "permanentDistrict": permanentDistrict,
                "presentState": presentState,
                "permanentState": permanentState
              })
        });

        console.log("req body", {
            "applicationId": applicationId,
            "presentAddress": presentAddress,
            "permanentAddress": permanentAddress,
            "presentPinCode": presentPinCode,
            "permanentPinCode": permanentPinCode,
            "presentDistrict": presentDistrict,
            "permanentDistrict": permanentDistrict,
            "presentState": presentState,
            "permanentState": permanentState
          });

        response = await response.json();
        // console.log(response);
        
        return response;
    }

    static async updateDocuments(applicationId, newUploads, token, totalDocuments){
        // console.log("newUploads", newUploads);
        const fd = new FormData();
        // console.log("formData", fd);
        fd.append('applicationId', applicationId);
        
        fd.append('allDocsUploaded', totalDocuments);
        
        for (const a of newUploads){
           fd.append(a.name, a.file);
        }
        // fd.append('documents', newUploads);
        // console.log("formData", ...fd);
        let response = await fetch(API_URL + "/api/update-documents", {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: fd
        });

        response = await response.json();
        // console.log(response);
        
        return response;
    }

    static async updateExperience(applicationId, experience, token){
        let response = await fetch(API_URL + "/api/update-experience", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                "applicationId": applicationId,
                "experience": experience
            })
        });

        response = await response.json();
        // console.log(response);
        
        return response;
    }

    static async updateComputerExperience(applicationId, experience, token){
        let response = await fetch(API_URL + "/api/update-computer-experience", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                "applicationId": applicationId,
                "experience": experience
            })
        });

        response = await response.json();
        // console.log(response);
        
        return response;
    }

    static async updateEducation(applicationId, class10School, class10Marks, class10Stream, class12School, class12Marks, class12Stream, graduationSchool, graduationMarks, graduationStream, postGraduationSchool, postGraduationMarks, postGraduationStream, token,
        class10YOP, class10Board, class12YOP, class12Board, graduationYOP, graduationBoard, postGraduationYOP, postGraduationBoard){
        let response = await fetch(API_URL + "/api/update-education", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                "applicationId": applicationId,
                "class10School": class10School,
                "class10Marks": class10Marks,
                "class10Stream": class10Stream,
                "class12School": class12School,
                "class12Marks": class12Marks,
                "class12Stream": class12Stream,
                "graduationSchool": graduationSchool,
                "graduationMarks": graduationMarks,
                "graduationStream": graduationStream,
                "postGraduationSchool": postGraduationSchool,
                "postGraduationMarks": postGraduationMarks,
                "postGraduationStream": postGraduationStream,
                "class10YOP": class10YOP,
                "class10Board": class10Board,
                "class12YOP":class12YOP,
                "class12Board": class12Board,
                "graduationYOP": graduationYOP,
                "graduationBoard": graduationBoard,
                "postGraduationYOP": postGraduationYOP,
                "postGraduationBoard": postGraduationBoard
              })
        });

        response = await response.json();
        // console.log(response);
        
        return response;
    }

    static async updatePersonal(applicationId, fatherName, motherName, maritalStatus, spouseName, token){
        let response = await fetch(API_URL + "/api/update-personal", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                "applicationId": applicationId,
                "fatherName": fatherName,
                "motherName": motherName,
                "maritalStatus": maritalStatus,
                "spouseName": spouseName
              })
        });

        response = await response.json();
        // console.log(response);
        
        return response;
    }

    static async getOrCreateApplication(positionId, token) {
        let response = await fetch(API_URL + "/api/create-application", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                "positionId": positionId
              })
        });

        response = await response.json();
        // console.log(response);
        
        return response;
    }

    static async getHallTickets(token) {
        let response = await fetch(API_URL + "/api/get-halltickets", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        response = await response.json();

        return response;
    }

}