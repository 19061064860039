import React, {useState, Redirect, useEffect} from "react";
import PositionService from "../services/PositionService";
import Application from "./Application/Application";

export default function ApplyComponent({token}){
  

    const [positions, setPositions] = useState([]);

    if (positions.length === 0){
        PositionService.getPositions(token).then((resolve) => {
          //console.log("resolve response", resolve);
            setPositions(resolve.data.positions);
          }, 
          (reject) => {
            //console.log("rejected response", reject);
          }
        );
    }

    
    return (
      <>
          <div className="container ">
            <center>
            <p>Note: If application is filled up and submitted in one Group, the candidate cannot apply for a different post under the same Group 
</p></center>
            <table className="table table-hover">
                <thead className="table-dark">
                <tr>
                  <th scope="col">Position</th>
                  <th scope="col">Post-Code</th>
                  <th scope="col">Age (years)</th>
                  <th scope="col">Level</th>
                  <th scope="col">Group</th>
                  <th scope="col">No. of Vacancies</th>
                  <th scope="col">Reservation category</th>
                  <th scope="col">Qualification</th>
                  <th scope="col">Experience</th>
                  <th scope="col">Apply</th>
                </tr>
                </thead>
                <tbody>
                    { positions.map( position => (
                        <tr key={Date.now()+Math.random()*1000}>
                          <td key={Date.now()+Math.random()*1000}>{position.positionName}</td>
                          <td key={Date.now()+Math.random()*1000}>{position.postCode}</td>
                          <td key={Date.now()+Math.random()*1000}>{position.ageRange}</td>
                          <td key={Date.now()+Math.random()*1000}>{position.level}</td>
                          <td key={Date.now()+Math.random()*1000}>{position.group}</td>
                          <td key={Date.now()+Math.random()*1000}>{position.vacancy}</td>
                          <td key={Date.now()+Math.random()*1000}>{Object.entries(position.reservationCategories).map( ([category,vacancy], i) => (<div key={category}>{category}({vacancy})<br></br></div>))}</td>
                          <td key={Date.now()+Math.random()*1000}>{position.qualification === "Graduate" ? "3 Years Diploma in Civil Engineering / Technology" : position.qualification}</td>
                          <td key={Date.now()+Math.random()*1000}>{position.experience.map(entry => (<div>{entry.name}</div>))}</td>
                          <td key={position._id+"-apply"} className="align-middle"><a href={"/application?positionId="+position._id} className="btn btn-sm btn-success">Apply</a></td>
                        </tr>
                      ))}
                </tbody>
                
            </table>
            { positions.length === 0 &&
                      <div className="h6">Loading positions..</div>
                    }

            
          </div>
      </>
    );
    
  }