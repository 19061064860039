import { Button } from "bootstrap";
import React, {useState, useRef, useEffect} from "react";
import ApplicationService from "../../services/applicationService";
import RedStar from "../RedStar";
import SaveButtonComponent from "./SaveButtonComponent";

export default function PersonalDetailForm({application, setApplication, token, isFormLocked}){

    const [fatherName, setFatherName] = useState("");
    const [motherName, setMotherName] = useState("");
    const [maritalStatus, setMaritalStatus] = useState("");
    const [spouseName, setSpouseName] = useState("");
    const [formError, setFormError] = useState("");
    const [formSuccess, setFormSuccess] = useState("")
    const [formUpdated, setFormUpdated] = useState(false);

    const formRef = useRef()

    useEffect(() => { 
      if (formError !== ""){
          alert("ERROR: "+formError);
      }
  }, [formError])

    useEffect(() => {
      setFormUpdated(true);
    }, [fatherName, motherName, maritalStatus, spouseName])

    useEffect(() => {
      // Prefill data if available
      if (!application || !application.personal) return;

      setFatherName(application.personal.fatherName);
      setMotherName(application.personal.motherName);
      setMaritalStatus(application.personal.maritalStatus);
      setSpouseName(application.personal.spouseName);
    }, [application]);

    const handleSubmit = () => {
      //console.log("handle submit", fatherName, motherName, maritalStatus);
      if (!fatherName || fatherName === ""){
        setFormError("Father name cannot be empty");
        return;
      }

      if (!motherName || motherName === ""){
        setFormError("Mother name cannot be empty");
        return;
      }

      if (!maritalStatus || maritalStatus === "" || maritalStatus === "--select--"){
        setFormError("Marital status cannot be empty");
        return;
      }

      if (maritalStatus && maritalStatus === "married" && spouseName === ""){
        setFormError("Spouse name cannot be empty");
        return;
      }

      setFormError("");

      // update form
      setFormUpdated(false);
      ApplicationService.updatePersonal(application._id, fatherName, motherName, maritalStatus, spouseName, token).then((resolve) => {
          if (resolve.status === 200){
            const p = resolve.data.application;
              if (p){
                  //console.log("Got application", p);
                  setApplication(p);
                  setFormSuccess("Personal Details saved successfully!");
                  setTimeout(() => {
                    setFormSuccess("");
                  }, 5000);
                  setFormUpdated(false);
              }else{
                  setFormError(resolve.data.message);
              }
          }else{
              console.error("error in updating personal details", resolve);
              setFormError(resolve.message);
              setFormUpdated(true);
          }
      }, (reject) => {
          setFormError("Failed to update personal details. "+reject.message);
          console.error("error in updating personal details", reject);
      })

    }

    if (!application)
      return <></>
    
    return (
      <>
            <div className="card no-break">
              <div className="card-body">
                  <div className="card-title d-flex justify-content-between">
                        <h5>Personal Details </h5> {formUpdated && <p className="px-1 py-1 rounded bg-warning text-black"><strong>Unsaved!</strong></p>}
                  </div>
                <div className="form" ref={formRef}>
                  
                  <div className="row">
                    <div className="form-group col">
                        <label> 
                            Father's Name <RedStar></RedStar>
                        </label>
                        <input maxLength={50} disabled={(isFormLocked || !formUpdated)?true:""} className='form-control' style={{textTransform: "uppercase"}} onChange={e => setFatherName(e.target.value)} type="text" value={fatherName}/>
                    </div>
                    <div className="form-group col">
                        <label> 
                            Mother's Name <RedStar></RedStar>
                        </label>
                        <input maxLength={50} disabled={(isFormLocked || !formUpdated)?true:""} className='form-control' style={{textTransform: "uppercase"}} onChange={e => setMotherName(e.target.value)} type="text" value={motherName}/>
                    </div>
                    <div className="form-group col">
                        <label> 
                            Marital Status <RedStar></RedStar>
                        </label>
                        <select disabled={(isFormLocked || !formUpdated)?true:""} className='form-control' onChange={e => setMaritalStatus(e.target.value)} type="text" value={maritalStatus}>
                            <option value>--select--</option>
                            <option value="single">single</option>
                            <option value="married">married</option>
                        </select>
                    </div>
                    { maritalStatus === "married" &&
                      <div className="form-group col">
                        <label> 
                            Spouse Name <RedStar></RedStar>
                        </label>
                        <input maxLength={50} disabled={(isFormLocked || !formUpdated)?true:""} className='form-control' style={{textTransform: "uppercase"}} onChange={e => setSpouseName(e.target.value)} type="text" value={spouseName}/>
                      </div>
                    }
                  </div>
                  <br></br>
                  <div className="row text-center">
                    <small className="text-danger">{formError}</small>
                    <small className="text-success">{formSuccess}</small>
                  </div>
                  <SaveButtonComponent isFormLocked={isFormLocked} formUpdated={formUpdated} setFormUpdated={setFormUpdated} handleSubmit={handleSubmit}/>
                </div>
              </div>

            </div>
            <br></br>
      </>
    );
    
  }