import React, {useState} from "react";
import Login from "../Auth/Login";
import Home from "../Home/Home";
import brandlogo from "../../brandlogo.png"

import { useSearchParams } from 'react-router-dom';
import ApplicationReviewComponent from "./ApplicationReviewComponent";

import ApplicantService from "../../services/applicantService";


export default function Review(){
    const [searchParams] = useSearchParams();
    // console.log(searchParams);

    const applicationId = searchParams.get('applicationId');

    const [token, setToken] = useState("");
    const [user, setUser] = useState({});

    if (!applicationId || applicationId === ""){
        return <Home />
    }
    
    // console.log("applicationId", applicationId);

    const tok = localStorage.getItem("token");
    // console.log("token", tok);
    if(!token) {
        if (tok && tok !== ""){
            setToken(tok);
            if (!user.firstName){
                ApplicantService.getProfile(tok).then((resolve) => {
                    // console.log("resolve response", resolve);
                    if (resolve.status === 200){
                        setUser(resolve.data.user);
                    }else{
                        // navigate("/");
                    }
                }, (reject) => {
                //   console.log("rejected response", reject);
                });
            }
        }else{
            // console.log("redirect to login");
            return <Login setToken={setToken}/>
        }
    }else{
        // console.log("returned to Application", token);
    }
    
    return (
        <div className="">
           <header style={{backgroundColor:"beige", minHeight:"7vw", position:'relative', display: "flex", justifyContent: "space-evenly", alignItems: "center", overflowX: "auto"}}>
            <a className="" href="/" style={{}}><img style={{height:"5vw"}} src={brandlogo} alt="logo"/></a>
            
            <img className='' style={{height:"7vw", marginLeft: "10%"}} alt='AIDC' src='header_image_removebg.png'/>

            <nav className="navigation" style={{width:"20vw"}}>
                
            </nav>
        </header>

<br></br><br></br>
            <ApplicationReviewComponent token={token} applicationId={applicationId} user={user}/>
            <br></br>
        </div>
    );
    
}