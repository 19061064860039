import React, {useState} from "react";
import ApplicantService from "../../services/applicantService";
import parse from 'html-react-parser';
import brandlogo from "../../brandlogo.png";
import RedStar from "../RedStar";
import Home from "../Home/Home";
import { EmploymentExchangeDistricts } from "../../constants";


export default function Register(){
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dob, setDob] = useState("");
    const [category, setCategory] = useState("");
    const [qualification, setQualification] = useState("");
    const [mobile, setMobile] = useState("");
    const [isPWD, setIsPWD] = useState(false);
    const [isPWDText, setIsPWDText] = useState("NO")
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [PWDMeasurable, setPWDMeasurable] = useState("");
    const [UDID, setUDID] = useState("");
    const [PWDCategory, setPWDCategory] = useState("");
    const [isResident, setIsResident] = useState(false);
    const [isResidentText, setIsResidentText] = useState("NO");
    const [isExMilitary, setIsExmilitary] = useState(false);
    const [isExMilitaryText, setIsExmilitaryText] = useState("NO");
    const [isEWS, setIsEWS] = useState(false);
    const [isEWSText, setIsEWSText] = useState("NO");
    const [employmentExchangeDistrict, setemploymentExchangeDistrict] = useState("");
    // const [signature, setSignature] = useState();
    // const [photograph, setPhotograph] = useState();
    const [loginErr, setLoginErr] = useState("");
    const [formSuccess, setFormSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [registerSuccess, setRegisterSuccess] = useState(false);
    const [employmentExchangeNumber, setEmployeeExchangeNumber] = useState("");

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        // const files = {
        //     "photograph": photograph,
        //     "signature": signature
        // }
        
        const formData = new FormData();
        formData.append("firstName", firstName.toLowerCase());
        formData.append("middleName", middleName.toLowerCase());
        formData.append("lastName", lastName.toLowerCase());
        formData.append("dob", dob);
        formData.append("category", category);
        formData.append("qualification", qualification);
        formData.append("mobile", mobile);
        formData.append("isPWD", isPWD);    
        formData.append("email", email.toLowerCase());
        formData.append("gender", gender);
        formData.append("PWDMeasurable", PWDMeasurable);
        formData.append("UDID", UDID);
        formData.append("PWDCategory", PWDCategory);
        formData.append("isResident", isResident);
        formData.append("isExMilitary", isExMilitary);
        formData.append("isEWS", isEWS);
        formData.append("employmentExchangeNumber", employmentExchangeNumber);
        formData.append("employmentExchangeDistrict", employmentExchangeDistrict);
        // formData.append("pinCode", pinCode);
        // formData.append("signature", signature);
        // formData.append("photograph", photograph);
        const data = await ApplicantService.register(formData);
        //console.log("data", data);
        if (data.status === 200){
            setFormSuccess(data.message);
            setLoginErr(null);
            //console.log("success", data);
            // document.getElementById("register-form").reset();
            alert('Registration ID AND Password is being sent to the Registered Email..');
            setTimeout(() => {
                setFormSuccess("");
                setRegisterSuccess(true);
              }, 10000);
        }else{
            setFormSuccess(null)
            setLoginErr(data.message);
            let errors = data.message +"<br>"
            if (data.error){
                for (let i = 0; i < data.error.length; i++) {
                    const element = data.error[i];
                    errors += element + "<br>"
                }
                setLoginErr(parse(errors))
            }
            //console.log("error state updated", data.message);
            setTimeout(() => {
                setLoginErr("");
              }, 10000);
        }

        setLoading(false);
        
      }

    const cardStyle = {
        minWidth:'300px'
    };

    if (registerSuccess){
        return <Home />
    }

  return(
    <div className='text-center'> 
        <header style={{backgroundColor:"beige", minHeight:"7vw", position:'relative', display: "flex", justifyContent: "space-evenly", alignItems: "center", overflowX: "auto"}}>
            <a className="" href="/" style={{}}><img style={{height:"5vw"}} src={brandlogo} alt="logo"/></a>
            
            <img className='' style={{height:"7vw", marginLeft: "10%"}} alt='AIDC' src='header_image_removebg.png'/>

            <nav className="navigation" style={{width:"20vw"}}>
                <a className="btn btn-sm mx-1" style={{borderColor: 'white', fontSize:"1vw"}} href="/">Home</a>
                <a className="btn btn-sm mx-1" style={{borderColor: 'white', fontSize:"1vw"}} href="/login">Login</a>
                <a className="btn btn-sm mx-1" style={{borderColor: 'white', fontSize:"1vw"}} href="/reset-password">Reset Password</a>
            </nav>
        </header>
            <br></br>
        <div className='row'>
            <div className='col' style={cardStyle}>
                    <div className='card mx-auto '>
                    <p className='card-header'>Applicant Registration</p>
                    <div className='card-body'>
                        <p className="text-start">Fields marked with <RedStar></RedStar> are mandatory.
Read the below instructions carefully, before filling the form:<br></br>
1. Candidate has to fill in the below mentioned details to receive
the User ID and Password.<br></br>
2. Candidate will receive the User ID and Password on the registered
email address.<br></br>
3. Candidate can login with the User ID and Password to complete
the application form for AIDC recruitment<br></br>
4. Candidate must provide Correct Name, Date of Birth, Mobile
Number and Email Address as these details cannot be changed
once the registration is complete.</p>
                        <form className='form bg-light py-1 px-1' id="register-form">
                            <div className="row py-2">
                                <div className="col form-group">
                                    <label > 
                                        First Name <RedStar></RedStar>
                                    </label>
                                    <input className='form-control' maxLength={30} style={{textTransform: "uppercase"}} onChange={e => setFirstName(e.target.value)} type="text" id='FirstName' name='FirstName'/>
                                </div>
                            
                                <div className="col form-group">
                                    <label > 
                                        Middle Name
                                    </label>
                                    <input className='form-control' maxLength={30} style={{textTransform: "uppercase"}} onChange={e => setMiddleName(e.target.value)} type="text" id='MiddleName' name='MiddleName'/>
                                </div>
                                <div className="col form-group">
                                    <label > 
                                        Last Name <RedStar></RedStar>
                                    </label>
                                    <input className='form-control' maxLength={30} style={{textTransform: "uppercase"}} onChange={e => setLastName(e.target.value)} type="text" id='LastName' name='LastName'/>
                                </div>
                            </div>

                            <div className="row py-1">
                                <div className="col form-group">
                                    <label > 
                                        Dob DD-MM-YYYY <RedStar></RedStar>
                                    </label>
                                    <input className='form-control' onChange={e => setDob(e.target.value)} type="date" id='Dob' name='Dob'/>
                                </div>
                                <div className="col form-group">
                                    <label > 
                                        Category <RedStar></RedStar>
                                    </label>
                                    <select className='form-control' onChange={e => setCategory(e.target.value)} type="text" id='Category' name='Category' value={category}>
                                        <option> -- select an option -- </option>
                                        <option value="UR">UR</option>
                                        <option value="SC">SC</option>
                                        <option value="OBC">OBC/MOBC</option>
                                        <option value="ST(P)">ST(P)</option>
                                        <option value="ST(H)">ST(H)</option>
                                    </select>
                                </div>
                                
                                <div className="col form-group">
                                    <label > 
                                        Qualification <RedStar></RedStar>
                                    </label>
                                    <select className='form-control' onChange={e => setQualification(e.target.value)} type="text" id='Qualification' name='Qualification' value={qualification}>
                                        <option> -- select an option -- </option>
                                        <option value="Graduate">3 Years Diploma in Civil Engineering / Technology</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row py-2">
                                <div className="col form-group">
                                    <label > 
                                        Mobile <RedStar></RedStar>
                                    </label>
                                    <input className='form-control' onChange={e => setMobile(e.target.value)} type="number" id='Mobile' name='Mobile'/>
                                </div>
                                <div className="col form-group">
                                    <label > 
                                        Email <RedStar></RedStar>
                                    </label>
                                    <input className='form-control' maxLength={100} onChange={e => setEmail(e.target.value)} type="email" id='Email' name='Email'/>
                                </div>
                                <div className="col form-group">
                                    <label > 
                                        Gender <RedStar></RedStar>
                                    </label>
                                    <select className='form-control' onChange={e => setGender(e.target.value)} type="text" id='Gender' name='Gender' value={gender}>
                                        <option> -- select an option -- </option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                                
                                {/* <div className="col form-group">
                                    <label for="Address"> 
                                        Address
                                    </label>
                                    <input className='form-control' onChange={e => setAddress(e.target.value)} type="text" id='Address' name='Address'/>
                                </div> */}
                            </div>
                            <div className="row py-2">
                                <div className="col-4 form-group" style={{}}>
                                    <label > 
                                        Do you come under PWD section? <RedStar></RedStar>
                                    </label>
                                    <select className='form-control' onChange={e => {setIsPWD(e.target.value === "YES"? true: false); setIsPWDText(e.target.value)}} type="text" id='IsPWD' name='IsPWD' value={isPWDText}>
                                        <option value="NO">NO</option>
                                        <option value="YES">YES</option>
                                    </select>
                                </div>
                                
                                <div className="col form-group">
                                    <label>
                                        Percentage of Disability <RedStar></RedStar>
                                    </label>
                                    <input className='form-control' disabled={!isPWD} onChange={e => setPWDMeasurable(e.target.value)} type="number" value={PWDMeasurable} min={1} max={100} />
                                </div>
                                
                                <div className="col form-group">
                                    <label>
                                        PWD UDID number <RedStar></RedStar>
                                    </label> 
                                    <input className='form-control' disabled={!isPWD} onChange={e => setUDID(e.target.value)} type="text" value={UDID} />
                                </div>

                                <div className="col form-group">
                                    <label>
                                        PWD Category <RedStar></RedStar>
                                    </label> 
                                    <select className='form-control' disabled={!isPWD} onChange={e => setPWDCategory(e.target.value)} type="text" value={PWDCategory}>
                                        <option value=""> -- select an option -- </option>
                                        <option value="(a) Blindness and low vision">(a) Blindness and low vision</option>
                                        <option value="(b) Deaf and hard of hearing">(b) Deaf and hard of hearing</option>
                                        <option value="(c) Locomotor disability including cerebral palsy, leprosy cured, dwarfism, acid attack victims and muscular dystrophy">(c) Locomotor disability including cerebral palsy, leprosy cured, dwarfism, acid attack victims and muscular dystrophy</option>
                                        <option value="(d) Autism, intelluctual disability, specific learning disability and mental illness">(d) Autism, intelluctual disability, specific learning disability and mental illness</option>
                                    </select>
                                </div>
                                   
                            </div>
                            <div className="row py-2">
                                <div className="col-3 form-group" style={{}}>
                                    <label > 
                                        Are you an Ex-Serviceman? <RedStar></RedStar>
                                    </label>
                                    <select className='form-control' onChange={e => {setIsExmilitary(e.target.value === "YES"? true: false); setIsExmilitaryText(e.target.value)}} type="text" id='IsExMilitary' name='IsExMilitary' value={isExMilitaryText}>
                                        <option value="NO">NO</option>
                                        <option value="YES">YES</option>
                                    </select>
                                </div>
                                <div className="col-3 form-group" style={{}}>
                                    <label > 
                                        Are you a resident of Assam state? <RedStar></RedStar>
                                    </label>
                                    <select className='form-control' onChange={e => {setIsResident(e.target.value === "YES"? true: false); setIsResidentText(e.target.value)}} type="text" id='isResident' name='isResident' value={isResidentText}>
                                        <option value="NO">NO</option>
                                        <option value="YES">YES</option>
                                    </select>
                                </div>
                                
                                <div className="col-3 form-group" style={{}}>
                                    <label>
                                        Employment Exchange District <RedStar></RedStar>
                                    </label>
                                    <select className='form-control' disabled={!isResident} onChange={e => setemploymentExchangeDistrict(e.target.value)} type="text" value={employmentExchangeDistrict}>
                                        <option> -- select an option -- </option>
                                        {
                                            EmploymentExchangeDistricts.map( district =>(
                                                <option value={district}>{district}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-3 form-group" style={{}}>
                                    <label > 
                                        Employment Exchange Number <RedStar></RedStar>
                                    </label>
                                    <input className='form-control' disabled={!isResident} onChange={e => setEmployeeExchangeNumber(e.target.value)} type="text" value={employmentExchangeNumber} />
                                </div>
                            </div>
                            <div className="row py-2">
                                <div className="col-3 form-group" style={{}}>
                                    <label > 
                                        Do you come under EWS criteria? <RedStar></RedStar>
                                    </label>
                                    <select className='form-control' onChange={e => {setIsEWS(e.target.value === "YES"? true: false); setIsEWSText(e.target.value)}} type="text" id='isEWS' name='isEWS' value={isEWSText}>
                                        <option value="NO">NO</option>
                                        <option value="YES">YES</option>
                                    </select>
                                </div>
                            </div>
                            {/* <div className="form-group">
                                <label for="Pincode"> 
                                    Pincode
                                </label>
                                <input className='form-control' onChange={e => setPincode(e.target.value)} type="number" id='Pincode' name='Pincode'/>
                            </div> */}
                            {/* <div className="form-group">
                                <label for="Signature"> 
                                    Signature
                                </label>
                                <input className='form-control' onChange={e => {
                                    const [f] = e.target.files;
                                    setSignature(f);
                                }} type="file" id='Signature' name='Signature'/>
                            </div>
                            <div className="form-group">
                                <label for="Photograph"> 
                                    Photograph
                                </label>
                                <input className='form-control' onChange={e => {
                                    const [f] = e.target.files;
                                    setPhotograph(f);
                                }} type="file" id='Photograph' name='Photograph'/>
                            </div> */}
                            <div className="col">
                                    <br></br>
                                    <div>
                                <small className='text-danger'>{loginErr}</small>
                                <small className='text-success'>{formSuccess}</small>
                            </div>
                                <button disabled={loading?true:""} className='btn btn-success btn-sm px-5' type="submit" onClick={handleSubmit}>{loading ? "Processing.." : "Submit"}</button>
                                </div>
                            <br></br>
                        <center><small className='text-danger'>For any queries please contact us at admin@aidcrecruitment.co.in</small></center>
<br></br>
                            
                        </form>
                    </div>
                    <div className='card-footer'>Registration Id and password are shared with you via email after successful registration<br></br><br></br>
                        <div className='row mb-3'>
                            <div className='col'><a href="/" className='btn btn-primary btn-sm'>Login</a></div>
                            <div className='col'><a href="/reset-password" className='btn btn-primary btn-sm'>ResetPassword</a></div>
                        </div>
                    </div>
                        
                </div>
            </div>
        </div>
        <br></br>
        
        
    </div>
    
  );
}