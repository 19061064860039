import React, { useState } from "react";
import ApplicantService from "../../services/applicantService";
import brandlogo from "../../brandlogo.png";
import RedStar from "../RedStar";

export default function ResetPassword(){

    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dob, setDob] = useState();
    const [email, setEmail] = useState("");
    const [loginErr, setLoginErr] = useState();
    const [formSuccess, setFormSuccess] = useState();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        const data = await ApplicantService.resetPassword({
            "firstName": firstName.toLowerCase(),
            "middleName": middleName.toLowerCase(),
            "lastName": lastName.toLowerCase(),
            "dob": dob,
            "email": email
        });
        //console.log("data", data);
        if (data.status === 200){
            setFormSuccess(data.message);
            setLoginErr(null);
            alert('Registration ID AND Password is being sent to the Registered Email..');
            setTimeout(() => {
                setFormSuccess("");
                alert("You can proceed to login");
              }, 5000);
        }else{
            setFormSuccess(null)
            setLoginErr(data.message);
            //console.log("error state updated", data.message);
            setTimeout(() => {
                setLoginErr("");
              }, 10000);
        }
        setLoading(false);
      }

    const cardStyle = {
        minWidth:'300px'
    };

  return(
    <div className='text-center'> 
        <header style={{backgroundColor:"beige", minHeight:"7vw", position:'relative', display: "flex", justifyContent: "space-evenly", alignItems: "center", overflowX: "auto"}}>
            <a className="" href="/" style={{}}><img style={{height:"5vw"}} src={brandlogo} alt="logo"/></a>
            
            <img className='' style={{height:"7vw", marginLeft: "10%"}} alt='AIDC' src='header_image_removebg.png'/>

            <nav className="navigation" style={{width:"20vw"}}>
                <a className="btn btn-sm mx-1" style={{borderColor: 'white', fontSize:"1vw"}} href="/">Home</a>
                <a className="btn btn-sm mx-1" style={{borderColor: 'white', fontSize:"1vw"}} href="/register">Register</a>
            </nav>
        </header>
            <br></br>
        <div className='row'>
            <div className='col'/>
            <div className='col' style={cardStyle}>
                    <div className='card mx-auto '>
                    <p className='card-header'>Reset Password</p>
                    <div className='card-body'>
                        <form className='form'>
                            <div className="form-group">
                                <label > 
                                    First Name <RedStar></RedStar>
                                </label>
                                <input className='form-control' maxLength="30" style={{textTransform: "uppercase"}} onChange={e => setFirstName(e.target.value)} type="text" id='FirstName' name='FirstName'/>
                            </div>
                            <div className="form-group">
                                <label > 
                                    Middle Name
                                </label>
                                <input className='form-control' maxLength="30" style={{textTransform: "uppercase"}} onChange={e => setMiddleName(e.target.value)} type="text" id='MiddleName' name='MiddleName'/>
                            </div>
                            <div className="form-group">
                                <label > 
                                    Last Name <RedStar></RedStar>
                                </label>
                                <input className='form-control' maxLength="30" style={{textTransform: "uppercase"}} onChange={e => setLastName(e.target.value)} type="text" id='LastName' name='LastName'/>
                            </div>
                            <div className="form-group">
                                <label > 
                                    Dob DD-MM-YYYY <RedStar></RedStar>
                                </label>
                                <input className='form-control' onChange={e => setDob(e.target.value)} type="date" id='Dob' name='Dob'/>
                            </div>
                            <div className="form-group">
                                <label > 
                                    Email Id <RedStar></RedStar>
                                </label>
                                <input className='form-control' onChange={e => setEmail(e.target.value)} type="email" id='email' name='Email'/>
                            </div>
                            <br></br>
                            <div>
                                <button disabled={loading?true:""} className='btn btn-success btn-sm' type="submit" onClick={handleSubmit}>Submit</button>
                                <br/>
                                <small className='text-danger'>{loginErr}</small>
                                <br/>
                                <small className='text-success'>{formSuccess}</small>
                            </div>
                        </form>
                    </div>
                    <div className='card-footer'>RegistrationId and new password will be shared with you via email after successful password reset<br></br><br></br>
                        <div className='row mb-3'>
                            <div className='col'><a href="/" className='btn btn-primary btn-sm'>Login</a></div>
                        </div>
                    </div>
                        
                </div>
            </div>
            <div className='col'/>
        </div>
        
        
        
    </div>
    
  );
}