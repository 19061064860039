import React, {useState, useEffect} from "react";
import ApplicantService from "../../services/applicationService";
import PositionService from "../../services/PositionService";
import PositionComponent from "../PositionComponent";
import PersonalDetailForm from "./PersonalDetailForm";
import ApplicationStatusBar from "./ApplicationStatusBar";
import EducationDetailForm from "./EducationDetailForm";
import AddressDetailForm from "./AddressDetailForm";
import ExperienceDetailForm from "./ExperienceComponent";
import DocumentUploadComponent from "./DocumentUploadComponent";
import { useNavigate } from "react-router-dom";
import PaymentInfoComponent from "./PaymentInfoComponent";

export default function ApplicationComponent({positionId, token, user}){

    const [position, setPosition] = useState();
    const [application, setApplication] = useState();
    const [isFormLocked, setIsFormLocked] = useState(false);
    const [applicationError, setApplicationError] = useState(null);
    const navigator = useNavigate();

    useEffect(()=>{
        PositionService.getPositions(token).then((resolve) => {
            //console.log("resolve response", resolve);
              if (resolve.status === 200){
                const p = resolve.data.positions.find(x => x._id === positionId);
                if (p){
                    setPosition(p);
                }else{
                    setPosition();
                }
              }else{
                setPosition();
              }
            }, 
            (reject) => {
                setPosition();
                //console.log("rejected response", reject);
            }
          );
    }, [positionId, token])

    useEffect(() => {
        ApplicantService.getOrCreateApplication(positionId, token).then((resolve) => {
            if (resolve.status === 200){
                const p = resolve.data.application;
                if (p){
                    //console.log("Got application", p);
                    setApplication(p);
                    if (p.status === "submitted" || p.status === "approved" || p.status === "rejected"){
                        setIsFormLocked(true);
                    }
                    if (p.status === "initiated"){
                        alert("New application initiated with applicationId: "+p.applicationId);
                    }
                    setApplicationError(null);
                }else{
                    setPosition();
                    setApplicationError(resolve.message);
                }
            }else{
                alert("error in creating application. "+resolve.message);
                console.error("error in creating application", resolve);
                setPosition();
                setApplicationError(resolve.message);
            }
        }, (reject) => {
            setPosition();
            //console.log("rejected response", reject);
            setApplicationError(`Error occurred in fetching application. ${reject}`)
        });
    }, [positionId, token])

    if (!position || !application){
        //console.log("position or application is undefined");
        return (<><h6>{applicationError}</h6></>)
    }
    else{
        //console.log("position and application is fetched");
        if (application.status === "submitted" || application.status === "approved" || application.status === "rejected"){
            navigator(`/review?applicationId=${application.applicationId}`);
        }
    }

    return (
        <>
            <div className="container">
                <div className="h6">Form for position {position.positionName}</div>
                <small className="text-danger">**Kindly ensure that you qualify for this post before continuing with the form</small>
                <PositionComponent position={position} />
                { applicationError &&
                    <div className="h6 text-danger">{applicationError}</div>
                }
                <ApplicationStatusBar application={application}/>
                <PersonalDetailForm application={application} setApplication={setApplication} token={token} isFormLocked={isFormLocked}/>
                <AddressDetailForm application={application} setApplication={setApplication} token={token} isFormLocked={isFormLocked}/>
                <EducationDetailForm application={application} setApplication={setApplication} token={token} isFormLocked={isFormLocked} position={position} user={user}/>
                <ExperienceDetailForm application={application} setApplication={setApplication} token={token} isFormLocked={isFormLocked} position={position}/>
                <PaymentInfoComponent application={application} setApplication={setApplication} token={token} isFormLocked={isFormLocked} position={position} applicant={user} />
                <DocumentUploadComponent application={application} setApplication={setApplication} token={token} isFormLocked={isFormLocked} position={position} user={user}/>
                {
                    !isFormLocked &&
                    <div className="card">
                        <div className="card-body">
                            <center>
                            <a className="btn btn-warning mx-5" href={`/review?applicationId=${application.applicationId}&positionId=${positionId}`}>Review and Submit Application</a>
                            </center>
                        </div>
                    </div>
                }
                    

            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </>
    )
}
