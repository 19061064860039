import React, {useEffect, useState} from "react";
import ApplicationService from "../../services/applicationService";

export default function ApplicationsListComponent({token}) {
    
    const [applications, setApplications] = useState([]);
    const [hallTickets, setHallTickets] = useState([]);

    useEffect(() => {
        ApplicationService.getUserApplications(token).then((resolve) => {
            setApplications(resolve.data.applications);

            ApplicationService.getHallTickets(token).then((resolve) => {
                setHallTickets(resolve.data.hallTickets);
            }, (reject) => {
                console.log("failed to fetch hall tickets", reject);
            });
        }, (reject) => {
            //console.log("rejected response", reject);
        })
    }, [token])

    return (
        <>
            {applications.length > 0 &&
                <div className="container">
                    <h6>Your Applications:</h6>
                    <table className="table table-hover">
                        <thead className="table-dark">
                        <tr>
                        <th scope="col">Application Id</th>
                        <th scope="col">Position</th>
                        <th scope="col">Status</th>
                        <th scope="col">Remark</th>
                        <th scope="col">Action</th>
                        <th scope="col">Admit Card</th>
                        </tr>
                        </thead>
                        <tbody>
                        { applications.map( application => (
                            <tr key={application._id}>
                            <td key={application.applicationId}>{application.applicationId}</td>
                            <td key={application.positionName}>{application.positionName}</td>
                            <td key={application.status}>{application.status}</td>
                            <td key={application.remark}>{application.remark}</td>
                            { (application.status !== "submitted" && application.status !== "approved" && application.status !== "rejected") &&
                            <td key={application._id+"-apply"} className="align-middle"><a href={"/application?positionId="+application.position} className="btn btn-sm btn-success">Edit</a></td>
                            }
                            { (application.status === "submitted" || application.status === "approved" || application.status === "rejected") &&
                            <td key={application._id+"-no-action"} className="align-middle"><a href={`/review?applicationId=${application.applicationId}`} className="btn btn-sm btn-primary">View/Print</a></td>
                            }
                            {
                                (hallTickets.find(item => item.postNames === application.positionName)) &&
                                    <td key={application._id+"-hallticket"} className="align-middle"><a href={"/my-hallticket?id="+hallTickets.find(item => item.postNames === application.positionName)._id} className="btn btn-sm btn-warning">View/Print</a></td>
                            }
                            {
                                (!hallTickets.find(item => item.postNames === application.positionName)) &&
                                <td key={application._id+"-hallticket"} className="align-middle">Not Released Yet</td>
                            }
                            </tr>
                        ))}
                        </tbody>
                    </table>  
                </div>
            }

            { applications.length === 0 &&
                <div className="container">
                    <h6>No applications submitted yet!</h6>
                    <a href="/apply" className="btn btn-primary btn-sm text-white ">Apply for Positions</a>
                </div>
            }
        </>
    )

}