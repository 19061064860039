import { API_URL } from "../constants";
import axios from "axios";
import authHeader from "./authHeader";

export default class PositionService{

    static async getAllExams(){
        let response = await fetch(`${API_URL}/api/get-all-exams`);

        response = await response.json();

        return response;
    }

    static async getPositions(token){
        let response = await fetch(`${API_URL}/api/get-positions`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        response = await response.json();
        // console.log("getpositions", response);
        return response;
    }
 
}