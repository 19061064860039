import { API_URL } from "../constants";

export default class ApplicantService{

    static daysInMonth (month, year) {
        // pass month+1 in month
        return new Date(year, month, 0).getDate();
    }

    static preciseDateDiff(date1, date2){
        if (date1.getTime() >= date2.getTime()){
            return {years:0,months:0,days:0};
        }

        const date1Year = date1.getYear();
        const date1Month = date1.getMonth();
        const date1Date = date1.getDate();
    
        const diff = {};
        let currentYear = date2.getYear();
        let currentMonth = date2.getMonth();
        let currentDate = date2.getDate();
        const daysInCurrentMonth = ApplicantService.daysInMonth(currentMonth+1, currentYear);
        if (currentDate < daysInCurrentMonth){
            currentDate++;
        }else{
            currentMonth++;
            currentDate=1;
    
            if (currentMonth > 11){
                currentYear++;
                currentMonth = 0;
            }
        }
    
        diff.years = currentYear-date1Year;
    
        if (currentMonth >= date1Month)  
            diff.months = currentMonth - date1Month;  
        else {  
            diff.years--;  
            diff.months = 12 + currentMonth - date1Month;  
        }  
    
        if (currentDate >= date1Date)
            diff.days = currentDate - date1Date;
        else{
            diff.months--;
            diff.days = ApplicantService.daysInMonth(diff.months+1, diff.years) + currentDate - date1Date;
    
            if (diff.months < 0){
                diff.months = 11;
                diff.years--;
            }
        }
    
        return diff;
    }

    static async getProfile(token){
        let response = await fetch(`${API_URL}/api/get-profile`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        response = await response.json();
        // console.log("getProfile", response);

        return response;
    }

    static async login(registrationId, password) {
        let response = await fetch(API_URL + "/api/login", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "registrationId":registrationId,
                "password":password
              })
        });

        response = await response.json();
        // console.log(response);
        
        return response;
      }

    static async register(applicantObject, files) {
        // console.log("Applicant object", applicantObject);
        let response = await fetch(API_URL + "/api/register", {
            method: 'PUT',
            headers: {
            },
            body: applicantObject
        });

        response = await response.json();
        // console.log(response);
        
        return response;
    }

    static async resetPassword(reqObject){
        let response = await fetch(API_URL + "/api/reset-password", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(reqObject)
        });

        response = await response.json();
        // console.log(response);
        
        return response;
    }
}